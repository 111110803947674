import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/psms/PromotionalSMS.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/psms/Bulkmessaging.svg';
import DNDfiltering from '../../assets/psms/DNDfiltering.svg';
import APIintegration from '../../assets/psms/APIintegration.svg';
import ContactManagement from '../../assets/psms/ContactManagement.svg';
import SMSScheduling from '../../assets/psms/SMSScheduling.svg';
import SenderIDCustomization from '../../assets/psms/SenderIDCustomization.svg';
import Vectorlogo from '../../assets/psms/CostandTimeEfficient.svg';
import IncreasedSalesConversions from '../../assets/psms/IncreasedSalesConversions.svg';
import Compliancesecurity from '../../assets/psms/Compliancesecurity.svg';
import PersonalizedMessaging from '../../assets/psms/PersonalizedMessaging.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const promotionalsms = () => {

    return (
        <>

            <Helmet>
                <title>Promotional SMS Service Provider | CallerSpot</title>
                <meta
                    name="description"
                    content="Get your message across with CallerSpot’s Promotional SMS Service Provider. Reach your target audience with ease and efficiency. Sign up today and start sending messages that matter!"

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Promotional SMS</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Boost Your Marketing Efforts with
                    Promotional SMS</p>
            </div>

            <section className="bgcolor">

                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">What is a Promotional SMS Solution?</h2>

                                <p className="passages1 mt-3">Promotional SMS solution is a marketing tool that enables businesses to send bulk text
                                    messages to customers for advertising purposes. It helps in promoting products, services,
                                    offers, and events. With its wide reach and high open rates, it&#39;s an effective way to engage
                                    with customers and drive sales.</p>
                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>


           
             
                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Features of Promotional SMS Solution</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>
                
              
                        <Row className="mt-4">
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Bulk messaging</Card.Title>
                                            <Card.Text>
                                                Send a large number of promotional messages to a wide audience
                                                simultaneously.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={DNDfiltering} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>DND filtering</Card.Title>
                                            <Card.Text>
                                                Automatically filter out numbers registered on the Do-Not-Disturb (DND)
                                                registry to ensure compliance with regulations.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={APIintegration} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>API integration</Card.Title>
                                            <Card.Text>
                                                Seamlessly integrate the Promotional SMS Solution with your existing
                                                systems or applications for streamlined operations.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={ContactManagement} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Contact Management</Card.Title>
                                            <Card.Text>
                                                Organize and manage recipient lists efficiently, allowing for targeted
                                                messaging and segmentation.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={SMSScheduling} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>SMS Scheduling</Card.Title>
                                            <Card.Text>
                                                Plan and schedule SMS campaigns in advance, ensuring messages are
                                                delivered at the most optimal times.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '240px'}}>
                                        <Card.Img variant="top" src={SenderIDCustomization} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Sender ID Customization</Card.Title>
                                            <Card.Text>
                                                Customize the sender ID to reflect your brand name, increasing
                                                brand recognition and trust.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
     

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Promotional SMS Solution</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Cost and Time Efficient</h3>
                                    <p>Promotional SMS is a cost efficient and time efficient solution
                                        compared to traditional marketing promotional sms. It offers a higher return on investment
                                        and is suitable for businesses of all sizes in less amount of time.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={PersonalizedMessaging} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Personalized Messaging</h3>
                                    <p>Customise your SMS content to appeal to your intended
                                        audience.. Customize messages based on customer preferences, demographics, or
                                        purchase history, increasing engagement and conversions.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={IncreasedSalesConversions} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col  md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Increased Sales and Conversions</h3>
                                    <p>Promotional SMS drives sales by directly engaging
                                        customers with compelling offers, discounts, or product updates. It encourages immediate
                                        action, leading to higher conversions.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Compliancesecurity} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Compliance and security</h3>
                                    <p>Promotional SMS solutions follow regulatory rules, assuring your
                                        company&#39;s compliance. Your information and that of your customers are kept safe.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                 
                </Container>
            </section>



            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="test">FAQs</h2>
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What exactly is a promotional SMS gateway?</Accordion.Header>
                                    <Accordion.Body>
                                        A promotional SMS gateway is a platform that allows businesses to send bulk promotional
                                        text messages to a large number of recipients. It acts as a bridge between the business and
                                        the telecommunication network for delivering SMS messages.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. Who can get promotional SMS messages?</Accordion.Header>
                                    <Accordion.Body>
                                        Promotional SMS messages can be received by individuals who have opted in to receive
                                        such messages. Typically, customers who have provided their consent or subscribed to
                                        promotional content from the company can receive these messages.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What exactly is DND?</Accordion.Header>
                                    <Accordion.Body>
                                        DND stands for &quot;Do Not Disturb.&quot; It is a service provided by telecom regulatory authorities
                                        that allows individuals to opt out of receiving promotional messages or calls.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. In which countries do you provide promotional SMS services?</Accordion.Header>
                                    <Accordion.Body>
                                        CallerSpot provides promotional SMS services in multiple countries. For specific information
                                        about the countries we cover, please visit our website or contact our customer support.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );



};
export default promotionalsms;