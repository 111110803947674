import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/acb/AIChatBot.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/acb/CustomizableUI.svg';
import ProtectsInformation from '../../assets/acb/ProtectsInformation.svg';
import Managesnewandoldintents from '../../assets/acb/Managesnewandoldintents.svg';
import Collectsuserdetails from '../../assets/acb/Collectsuserdetails.svg';
import Supportsfieldagents from '../../assets/acb/Supportsfieldagents.svg';
import Widespreadavailability from '../../assets/acb/Widespreadavailability.svg';
import ConversionRate from '../../assets/acb/ConversionRate.svg';
import BotPopUp from '../../assets/acb/BotPopUp.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/acb/Multilanguage.svg';
import availability from '../../assets/acb/247availability.svg';
import ScheduleFollowUps from '../../assets/acb/ScheduleFollowUps.svg';
import SoftwareAssistants from '../../assets/acb/SoftwareAssistants.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

   

    return (
        <>

<Helmet>
        <title>
        Artificial Intelligence Service for Conversational Chatbots | CallerSpot
        </title>
        <meta
            name="description"
            content="Enhance your chatbot's conversational prowess with CallerSpot's top-notch Artificial Intelligence service. Empower your bot to engage, understand, and delight users like never before. Elevate your customer interactions with our advanced AI technology today."
        />

    </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education education1 text-center text-white pt-4">AI ChatBot / VoiceBot Solution</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>AI-powered conversational Bots that take customer experience to another level with ChatBot/VoiceBot Solutions</p>
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What Is AI ChatBot/VoiceBot Solution?</h2>

                            <p className="passages1 mt-3">Voicebot Solution is a newbie technology that is created to enabling natural conversation by combining machine learning and natural language processing. The AI-powered Bots enables the users get Omni channel experience through WhatsApp, Website, and virtual assistant AI-based technologies. Chatbots were developed to automate conversations for lead generation and tech support <br /> If you want to scale your business by bringing up the customer experience to another level, we advise you to understand the demography of voice bots. As these Voicebots are ideal to push the customer experience to another level, businesses in different industries are using this technology to scale up</p>
                        </Col>

                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>

                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">AI ChatBot/VoiceBot Solution Features</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>

                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Customizable UI</Card.Title>
                                    <Card.Text>
                                        Its customizable UI helps the users to change themes, change Bot icon, and enable sticky buttons for common queries.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={ProtectsInformation} className="mt-3" />
                                <Card.Body>
                                    <Card.Title> Protects Information</Card.Title>
                                    <Card.Text>
                                        It makes your customers feel secure by masking their sensitive data.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={Managesnewandoldintents} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Manages new and old intents</Card.Title>
                                    <Card.Text>
                                        It creates and edits intents by importing/exporting URLS or excel files.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>

                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={Collectsuserdetails} className="mt-3" />
                                <Card.Body>
                                    <Card.Title> Collects user details</Card.Title>
                                    <Card.Text>
                                        It boosts leads and increases sales by collecting user details.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={BotPopUp} className="mt-3" />
                                <Card.Body>
                                    <Card.Title> Bot Pop-Up</Card.Title>
                                    <Card.Text>
                                        Enable Auto pop-up to support customers during their documentation work.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '237px'}}>
                                <Card.Img variant="top" src={Supportsfieldagents} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Supports field agents</Card.Title>
                                    <Card.Text>
                                        Supports fields staffs for ensuring better customer experience.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                    </Row>

                </Container>
            </section>




            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit"> AI ChatBot/VoiceBot Solution Benefits</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Multiple Languages</h3>
                                    <p>Resolves customer enquiries in a number of languages.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={availability} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">24x7 availability</h3>
                                    <p>AI VoiceBot is ideal to be available throughout the day, i.e., 24x7.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Widespreadavailability} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Widespread availability</h3>
                                    <p>Enables the users to contact the customers on WhatsApp, Website, and virtual assistant AI-technologies.</p>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ConversionRate} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Conversion Rate</h3>
                                    <p>Boosts Lead Conversion Rate Helps the users in filling forms & reduce drop-offs over call/chat.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ScheduleFollowUps} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Schedule Follow-Ups</h3>
                                    <p>Helps in customer follow up by sending reminders on WhatsApp.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={SoftwareAssistants} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Software Assistants</h3>
                                    <p>Uses conversational AI to understand and respond to callers using their voice.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );


};
export default Virtual;