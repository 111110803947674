import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/nm/numbermasking.svg';
import numbermasking from '../../assets/nm/numbermasking2.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/nm/AutoDialer.svg';
import DualWayMasking from '../../assets/nm/DualWayMasking.svg';
import RecordingofPhoneCalls from '../../assets/nm/RecordingofPhoneCalls.svg';
import IVR from '../../assets/nm/IVR.svg';
import CallHistory from '../../assets/nm/CallHistory.svg';
import CallAnalytics from '../../assets/nm/CallAnalytics.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/nm/RealTimeDataInsights.svg';
import CallMonitoringTracking from '../../assets/nm/CallMonitoringTracking.svg';
import ProtectCustomersPrivacy from '../../assets/nm/ProtectCustomersPrivacy.svg';
import TransactionsPlatformSpecific from '../../assets/nm/TransactionsPlatformSpecific.svg';
import Spendlessmoney from '../../assets/nm/Spendlessmoney.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Numbermasking = () => {

    return (
        <>

            <Helmet>
                <title>Number Masking | Call Masking Solution for Enterprise | CallerSpot</title>
                <meta
                    name="description"
                    content="Number Masking ensures business and customer privacy. CallerSpot's call masking solution protects the privacy of users by assigning a temporary virtual number to them."

                />
            </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">Phone Number Masking</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Take Control of Your Communication Privacy with Our Phone Number Masking Solution</p>
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What Is Phone Number Masking?</h2>

                            <p className="passages1 mt-3">Phone number masking also known as call masking, is a technology that is used to conceal a personal number during an incoming or outgoing call. When agents dial a customer call or receive a call then, phone number masking can replace it actual phone number (sender or receiver) with a proxy caller id. <br /> Call Masking Solution for Enterprise is generated by the cloud telephony that helps to hide the actual identity of the customer or agent a proxy caller id.</p>
                        </Col>


                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>
                    <Row className="mt-4 reverce">

                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={numbermasking} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>


                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Does Call Masking Work?</h2>

                            <p className="passages1 mt-3">When your businesses communicate to their customer then, Call or Phone number
                                masking plays an essential role with three steps: <br /> <br /> Assign a Phone Number: A caller ID will be set where your agent will speak to the
                                customer using caller ID. Also, you can choose your masked phone number from the
                                large virtual pool. You can set auto-assigned a temporary phone number. </p>

                            <ul>
                                <li className="passages1 mt-2">Securely Connect</li>
                            </ul>
                            <p className="passages1">The originate call masking provides to capture a way under the secure cloud server.
                                The outgoing will generate a caller ID that will mask a virtual phone number.</p>
                            <ul>
                                <li className="passages1 mt-2">Easily monitor &amp; track</li>

                            </ul>
                            <p className="passages1">The receiver will answer the call without showing or revealing their caller ID. Also, all
                                the calls will be recorded &amp; monitored in a real-time manner.</p>
                        </Col>

                    </Row>



                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Features of Number Masking</h2>
                            <p className="page" style={{'padding':'10px 0px'}}>The actual use of call masking is to provide Voice APIs &amp; virtual numbers that can
                                easily connect to the customer or agent without showing the actual or real phone
                                numbers between each other.</p>
                        </div>
                    </Row>

                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Auto Dialer</Card.Title>
                                        <Card.Text>
                                            Increases agent efficiency and customer engagement by automating outbound
                                            calls.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={IVR} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>IVR</Card.Title>
                                        <Card.Text>
                                            Answer any client inquiry using the IVR&#39;s multi-level menu to begin an
                                            engagement straight away.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={DualWayMasking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Dual-Way Masking</Card.Title>
                                        <Card.Text>
                                            Increase privacy during calls by disguising the phone numbers of both people
                                            engaged.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </Col>
                        {/* </div> */}
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={RecordingofPhoneCalls} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Recording of Phone Calls</Card.Title>
                                        <Card.Text>
                                            Reduce the possibility of fraud and improve service quality by automatically
                                            recording all interactions that occur on your platform.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </Col>
                        {/* </div> */}
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={CallHistory} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Call History</Card.Title>
                                        <Card.Text>
                                            Using our user-friendly platform, you can easily trace all of your inbound and
                                            outgoing calls and get call detail reports.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </Col>
                        {/* </div> */}
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            {/* <div md={4} sm={2} lg={4}> */}
                                <Card className="red" style={{'minHeight': '224px'}}>
                                    <Card.Img variant="top" src={CallAnalytics} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Call Analytics</Card.Title>
                                        <Card.Text>
                                            Improve your company strategy by analyzing key call KPIs and obtaining real-
                                            time data via a comprehensive dashboard.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </Col>
                        {/* </div> */}
                    </Row>
                </Container>
            </section>

            {/* <section>
                <Row>
                    <img src={Rectangle11} style={{'width':'100%'}} />
                </Row>
            </section> */}

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Number Masking Service</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Real-Time Data Insights</h3>
                                    <p>You may utilise call logs and actionable data insights on call performance to
                                        boost client engagement and analyse the effectiveness of your employees.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CallMonitoringTracking} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Call Monitoring and Tracking</h3>
                                    <p>Incoming and outgoing agents, as well as client calls, may be monitored for
                                        quality testing and data analysis.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ProtectCustomersPrivacy} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Protect Customers&#39; Privacy</h3>
                                    <p>During the transaction, your consumer&#39;s phone number is never made public.
                                        The corporation is ultimately accountable for preserving its consumers&#39;
                                        privacy.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={TransactionsPlatformSpecific} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Transactions that are Platform-Specific</h3>
                                    <p>Furthermore, implementing number masking to prevent off-platform
                                        transactions improves your monetization capabilities.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Spendlessmoney} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Spend less money</h3>
                                    <p>Call your clients without depleting your employees&#39; mobile phone balances. It
                                        is more cost effective to link them through us.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </section>


        </>
    );



};
export default Numbermasking;