import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/retailcom/RetailcommerceIndustry.svg';
import cloudcommunication from '../../assets/retailcom/cloudcommunication.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import ImprovetheROI from '../../assets/retailcom/TrackMarketingROIEffectively.svg';
import EasilyGeneraLeads from '../../assets/retailcom/EasilyGenerateLeads.svg';
import RemoteWorking from '../../assets/retailcom/RemoteWorking.svg';
import Securitconformity from '../../assets/retailcom/CollectFeedback.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Retailecommerce = () => {

    return (
        <>

            <Helmet>
                <title>Retail Ecommerce Industry | Cloud Telephony Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="CalleSpot is a cloud phone system for retail ecommerce businesses. Streamline customer experience for modern shoppers with CallerSpot."

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Retail &amp; E-commerce Industry</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Is Callerspot Helping The Retail &amp; E-commerce
                                Industry?</h2>

                            <p className="passages1 mt-3">Do you use cloud communications in your retail business to boost business
                                growth and save costs? Your customer and employee experiences will be
                                hampered if you continue to use old telephone systems and call centers.
                                Those technologies are becoming outdated as a result of unified
                                communications as a service (UCaaS) and contact center as a service
                                (CCaaS) delivered via the cloud.</p>

                        </Col>


                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>

                    <Row className="reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={cloudcommunication} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">Check out the benefits of cloud communication.</h2>
                            <ul>
                                <li className="passages1 mt-4">Serves clients efficiently and more efficaciously</li>
                                <li className="passages1 mt-1">Ensures advanced call quality and decreased expenses</li>
                                <li className="passages1 mt-1">Ensures smarter use of workers and IT sources more scalability</li>
                                <li className="passages1 mt-1">Helps IT employees to prioritize higher-degree enterprise tasks</li>
                                <li className="passages1 mt-1">Focuses on enabling hassle-free end-user communications for
                                    businesses</li>

                            </ul>
                            <p className="passages1 mt-3">In recent years, the Retail &amp; Ecommerce Industry is rapidly growing with
                                the users growing at an ever-increasing rate. Due to the increased user
                                numbers, it has become a difficult job for companies to handle the
                                increased number of users. If you own an e-commerce company, you need
                                to deal with a lot of issues. Cloud telephony solutions can be used to
                                handle all of this with ease.</p>

                        </Col>

                    </Row>

                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Cloud Communication Solution for Retail and E-commerce</h2>
                            <p className="page">Improve your communication with reply to every client enquiry, and
                                automate your Call, SMS handling with our Cloud Communication Solution.
                                <br />Our solution, designed exclusively for the Retail &amp; E-commerce Industry,
                                that assists you in organizing your processes, improving consumer
                                interaction, and driving sales.</p>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '340px'}}>
                                    <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Lead generation and sales</Card.Title>
                                        <Card.Text>
                                            Simplify the sales and lead creation process by using Cloud Contact
                                            Centre, Lead Management, and Missed Call Solution to increase sales with
                                            a customized approach matched to your needs.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '340px'}}>
                                    <Card.Img variant="top" src={ImprovetheROI} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Monitor Marketing ROI</Card.Title>
                                        <Card.Text>

                                            Connect Missed Call Solution and Virtual Business Numbers to your online
                                            and offline marketing activities to create a single multi-channel platform for
                                            tracking and monitoring.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '340px'}}>
                                    <Card.Img variant="top" src={RemoteWorking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Remote Working</Card.Title>
                                        <Card.Text>Increasing efficiency with a smart remote work Cloud Communication
                                            Technology and Video Platform that allows you to be accessible to your
                                            customers from anywhere at any time.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '340px'}}>
                                    <Card.Img variant="top" src={Securitconformity} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Customer Service &amp; Feedback</Card.Title>
                                        <Card.Text>Enable a customer-centric solution to earn every customer&#39;s trust by
                                            providing a refined customer experience and gathering feedback through
                                            Video Platform, Speech Analytics, Chatbots/Voicebots, Automated Calls,
                                            and SMS.
                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>










        </>
    );



};
export default Retailecommerce;