import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/sa/SpeechAnalytics.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/sa/Realtimespeech.svg';
import Speechadaptation from '../../assets/sa/Speechadaptation.svg';
import Multichannelconversation from '../../assets/sa/Multichannelconversation.svg';
import NoiseReduction from '../../assets/sa/NoiseReduction.svg';
import Managedatawithmultiplefilters from '../../assets/sa/Managedatawithmultiplefilters.svg';
import ConvertSpeechintotext from '../../assets/sa/ConvertSpeechintotext.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/sa/ReportAnalytics.svg';
import DecisionMaking from '../../assets/sa/DecisionMaking.svg';
import Lessexpense from '../../assets/sa/Lessexpense.svg';
import AccurateResults from '../../assets/sa/AccurateResults.svg';
import Multilanguage from '../../assets/sa/Multilanguage.svg';
import Manageentireoperationeffectively from '../../assets/sa/Manageentireoperationeffectively.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };



    return (
        <>

            <Helmet>
                <title>
                    Speech Analytics | Best AI Speech To Text Platform for Businesses - CallerSpot
                </title>
                <meta
                    name="description"
                    content="Enhance Customer Interactions with our Artificial Intelligence Service for Conversational Chatbots at CallerSpot. Deliver Seamless Conversations and Personalized Experiences. Try it Today!"
                />

            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Speech Analytics</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Improved Business Intelligence with Speech Analytics</p>
            </div>

            <section className="bgcolor">


                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is Speech Analytics?</h2>

                            <p className="passages1 mt-3">Speech Analytics also known as interactive analytics is just a technology that provides the benefits to artificial intelligence that can understand the actual procedure & analyze human speech. It uses digital channels like text messages & chat.</p>
                        </Col>


                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>



                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Features of Speech Analytics Platform</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>

                    <Row className="mt-5">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Real-time speech</Card.Title>
                                        <Card.Text>
                                            Real-time extraction of speech recognition results
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={Speechadaptation} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Speech adaptation</Card.Title>
                                        <Card.Text>
                                            Insert industry-specific or unique terms/phrases into your voice analytics application for automatic word identification.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={Multichannelconversation} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Multi-channel conversation</Card.Title>
                                        <Card.Text>
                                            Recognize and transcribe numerous participants in a discussion
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>

                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={NoiseReduction} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Noise-Reduction</Card.Title>
                                        <Card.Text>
                                            Easily recognizes and transforms audio from a chaotic background without noise cancellation
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={Managedatawithmultiplefilters} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Manage data with multiple filters</Card.Title>
                                        <Card.Text>
                                            Redact particular material such as improper language, private and personal information from your transcription.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '238px' }}>
                                    <Card.Img variant="top" src={ConvertSpeechintotext} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Convert Speech into text</Card.Title>
                                        <Card.Text>
                                            Make advantage of the large user base to cover worldwide languages by transforming voice to text.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>



            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Speech Analytics Platform</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Reports</h3>
                                    <p>Manage your time and get a transparent report.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={DecisionMaking} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Decision Making</h3>
                                    <p>It improves the decision-making strategy.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Lessexpense} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Less expense</h3>
                                    <p>It makes every process automatic & reduces the man work.</p>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={AccurateResults} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Accurate Results:</h3>
                                    <p>Relevant & specific results on year, currencies, addresses, etc</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Multilanguage} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Multi-language</h3>
                                    <p>Get the availability of more than 125 languages.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Manageentireoperationeffectively} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Manage the entire operation effectively</h3>
                                    <p>Get everything from beginning to end on just one platform.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>



            {/* <section className="mt-5">
                <Container>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                </Container>
            </section> */}

        </>
    );


};
export default Virtual;