import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/lm/LeadManagementimage.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/lm/Automation.svg';
import Prioritization from '../../assets/lm/Prioritization.svg';
import Reportingmonitoring from '../../assets/lm/Reportingmonitoring.svg';
import EmailSMSVoiceCommunication from '../../assets/lm/EmailSMSVoiceCommunication.svg';
import AnalyticsDashboard from '../../assets/lm/AnalyticsDashboard.svg';
import ConductSurveys from '../../assets/lm/ConductSurveys.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/lm/SimpleCRMIntegration.svg';
import QuickActivation from '../../assets/lm/QuickActivation.svg';
import Costeffective from '../../assets/lm/Costeffective.svg';
import IncreasedSalesandProfit from '../../assets/lm/IncreasedSalesandProfit.svg';
import MobileAccessibility from '../../assets/lm/MobileAccessibility.svg';
import Flexibleprice from '../../assets/lm/Flexibleprice.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const LeadManagement = () => {

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };


    return (
        <>

            <Helmet>
                <title>Leads Management Tracking Solution - CallerSpot </title>
                <meta
                    name="description"
                    content="Leading Cloud Telephony Solution for Businesses to Empower the business with the best-in-class IVR Solutions"
                />
            </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">Lead Management</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Improve Lead Conversion Rates and
                    Boost Sales Efficiency with Lead
                    Management System</p>
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is a Lead Management Solution?</h2>

                            <p className="passages1 mt-3">Lead management is the systematic process to qualifying, analysing, and stimulating
                                incoming leads in order to turn them into new business possibilities. Multi-channel leads are
                                often submitted into your lead management system, and ready-to-buy leads are transformed
                                into transactions. A lead management platform is required to ensure that potential clients
                                who are interested in your&#39;s product or services become regular customers.
                                When implemented effectively, lead management systems may fill your pipeline with
                                qualified, educated customers while also supporting the sales and demand generation
                                marketing teams in obtaining a deeper understanding of what buyers are looking for.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                        <img src={Rectangle} style={{ 'width': '100%', 'maxHeight': '80%' }} />
                        </Col>

                    </Row>

                    <Row>
                        <div className="text-center">
                            <h2 className="featured">Lead Management Software Features</h2>

                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Automation</Card.Title>
                                        <Card.Text>
                                            Automated the workflow of the telecallers are used a contacts list to
                                            place calls for lead generation and sales operations.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                </Card>
                            
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={Prioritization} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Prioritization</Card.Title>
                                        <Card.Text>
                                            Turn your phone into a call centre and make place sales support calls.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                </Card>
                            
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={Reportingmonitoring} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Reporting and monitoring</Card.Title>
                                        <Card.Text>
                                            Reports are essential for analysing lead quality and
                                            team efficiency.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                </Card>
                            
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={EmailSMSVoiceCommunication} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Email, SMS, and Voice Communication</Card.Title>
                                        <Card.Text>
                                            Engage leads directly through several
                                            communication channels.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                </Card>
                            
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={ConductSurveys} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Conduct Surveys</Card.Title>
                                        <Card.Text>
                                            Conduct customised surveys to get consumer feedback and
                                            insights for more business requirements.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            {/* </div> */}
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            
                                <Card className="reddled" style={{'minHeight': '247px'}}>
                                    <Card.Img variant="top" src={AnalyticsDashboard} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Analytics Dashboard</Card.Title>
                                        <Card.Text>
                                            Track the performance of your call centre and acquire useful
                                            business insights were you can easily monitoring end to end insights.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            {/* </div> */}
                        </Col>
                    </Row>

                </Container>

            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Lead Management Solution</h2>
                            <p style={{'padding':'10px 0px','color':'#023246'}}>In case you are going ahead to purchase an auto dialer or automatic calling dialer, the
                                next thing you want to know about is the advantages of an auto dialer.</p>
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Simple CRM Integration</h3>
                                    <p>Integrate with popular CRM software to automatically
                                        include customer data.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={QuickActivation} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Quick Activation</h3>
                                    <p>Upload your contact list and start calling campaigns within 6
                                        minutes to start calling campaingns.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Costeffective} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Cost-effective</h3>
                                    <p>There are no upfront costs or hidden fees; simply cloud-based calling
                                        with a smartphone.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={IncreasedSalesandProfit} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Increased Sales and Profit</h3>
                                    <p>Improve client connections while increasing total profit.    </p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Flexibleprice} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Flexible price</h3>
                                    <p>Cost management that is transparent, with pay-as-you-go price
                                        alternatives.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={MobileAccessibility} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Mobile Accessibility</h3>
                                    <p>For remote work, securely access the lead management
                                        system via your mobile app.</p>
                                </Col>
                            </Row>

                        </Col>

                    </Row>

                </Container>
            </section>


        </>
    );


};
export default LeadManagement;