import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets//ivr/IVRimage.svg';
import IVRimage2 from '../../assets//ivr/IVRimage2.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CollectFeedback from '../../assets/ivr/CollectFeedback.svg';
import AvailableRoundclock from '../../assets/ivr/AvailableRoundclock.svg';
import GetCustomizationFeature from '../../assets/ivr/GetCustomizationFeature.svg';
import QuickUpdates from '../../assets/ivr/QuickUpdates.svg';
import AvailableinMultipleLanguages from '../../assets/ivr/AvailableinMultipleLanguages.svg';
import BetterCustomerServiceExperience from '../../assets/ivr/BetterCustomerServiceExperience.svg';
import ImproveEfficiency from '../../assets/ivr/ImproveEfficiency.svg';
import GetAutomatedBasedOrderStatus from '../../assets/ivr/GetAutomatedBasedOrderStatus.svg';
import CashonDeliveryVerification from '../../assets/ivr/CashonDeliveryVerification.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import ReportAnalytics from '../../assets/ivr/ReportAnalytics.svg';
import SupportRemoteWorkingMethod from '../../assets/ivr/SupportRemoteWorkingMethod.svg';
import Routecallinanydirection from '../../assets/ivr/Routecallinanydirection.svg';
import Soundsoftprofessional from '../../assets/ivr/Soundsoftprofessional.svg';
import Smartcallingfacility from '../../assets/ivr/Smartcallingfacility.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Ivrservice = () => {

    return (
        <>

            <Helmet>
                <title>Cloud IVR Solutions | Cloud IVR Providers in India | CallerSpot </title>
                <meta
                    name="description"
                    content="To minimise your telephony set up cost, choose CallerSpot's Cloud IVR Solutions. Cloud-based IVR helps you build customised communication solutions for your business."

                />
            </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">Interactive Voice Response (IVR) Service</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Optimize Your Call Routing and Improve Efficiency with our Interactive Voice Response (IVR) Service.</p>
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is an Interactive Voice Response?</h2>

                            <p className="passages1 mt-3">IVR (meaning Interactive Voice Response) is the automated process of the telephone system where users can listen to the pre-recorded message or text speech technology. IVR calling helpsto engage the customer or user through the combination of voice inputs &amp; touch-tone keypad
                                selection and users can select or respond as per their requirements.
                                IVR solutions improve contact center operations &amp; increase customer satisfaction. IVR&#39;s calling
                                system is recognized by the customer care or customer support number and also, IVR
                                technology is well capable to provide their services 24X7 from any corner of the world.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5">
                            <img src={Rectangle} style={{ 'width': '100%', 'maxHeight': '80%' }} />
                        </Col>

                    </Row>
                    <Row className="reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5">
                            <img src={IVRimage2} style={{ 'width': '100%', 'maxHeight': '80%' }} />
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is Types of IVR (Interactive Voice Response)</h2>

                            <p className="passages1 mt-3">Mainly, IVR comes in two types namely Single-level IVR &amp; Multi-level IVR:</p>

                            <ul>
                                <li className="passages1 mt-3">Single-level IVR is based on an automated structure that generates a response only
                                    basis of a single input. It gives automated or pre-recorded answers to their users.</li>
                                <li className="passages1 mt-3">
                                    Multi-Level IVR: This IVR service is based on hierarchical structures that interact with
                                    their customer through a long list of input messages.
                                </li>
                            </ul>

                        </Col>


                    </Row>

                    <Row className="mt-3">
                        <div className="text-center">
                            <h2 className="featured">How do businesses use IVR?</h2>
                            <p className="page" style={{'padding':'10px 0px'}}>IVRs make any business comfortable, easier &amp; simplest to their customers or users.</p>
                        </div>
                    </Row>

                    <Row className="mt-5">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={CollectFeedback} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Collect Feedback</Card.Title>
                                        <Card.Text>
                                            IVR gives an excellent tracking facility where any business can perform the survey &amp;
                                            feedback for their products &amp; services.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={AvailableRoundclock} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Available Round O&#39;clock</Card.Title>
                                        <Card.Text>
                                            Organizations face challenges operating 24X7 but, IVR numbers can be available around
                                            O&#39;clock without any interruption.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={GetCustomizationFeature} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Get Customization Feature:</Card.Title>
                                        <Card.Text>
                                            IVR as a service that can easily convert according to the requirement or need of the
                                            customer. Organizations can send short or long messages.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={QuickUpdates} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Quick Updates</Card.Title>
                                        <Card.Text>
                                            Using IVR solutions, organizations can check the status of a large volume of incoming
                                            calls in a real-time manner such as order status, payment status &amp; many more.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={AvailableinMultipleLanguages} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Available in Multiple Languages</Card.Title>
                                        <Card.Text>
                                            Language plays an essential role in selling so IVR service providers can provide multiple
                                            language facilities.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={BetterCustomerServiceExperience} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Better Customer Service Experience</Card.Title>
                                        <Card.Text>
                                            IVR offers an excellent feature that can easily find or match their relevant customers as
                                            per their industry or domain.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={ImproveEfficiency} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Improve Efficiency</Card.Title>
                                        <Card.Text>
                                            IVR calling system helps to streamline any process and gives the liberty to staff to focus
                                            on essential activities.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={GetAutomatedBasedOrderStatus} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Get Automated-Based Order Status</Card.Title>
                                        <Card.Text>
                                            IVR tracking feature can easily track the order status &amp; payment status of the customer.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="redd" style={{'minHeight': '254px'}}>
                                    <Card.Img variant="top" src={CashonDeliveryVerification} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Cash on Delivery Verification</Card.Title>
                                        <Card.Text>
                                            Organization can easily perform their order verification procedure using the IVR calling
                                            facility.
                                        </Card.Text>
                                    </Card.Body>
                                  
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Why is an IVR number essential for your Business?</h2>

                        </div>
                    </Row>
                </Container>

            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="mt-2">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ReportAnalytics} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Report &amp; Analytics</h3>
                                    <p>Get real-time updates of the agents &amp; complete call analytics.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="mt-2">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={SupportRemoteWorkingMethod} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Support Remote Working Method</h3>
                                    <p>Ready to work anytime from any corner of the world.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="mt-2">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Routecallinanydirection} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Route call in any direction</h3>
                                    <p>Get the desired channel after pressing a few keys.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="mt-3">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Soundsoftprofessional} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Sound soft &amp; professional</h3>
                                    <p>Get a transparent customer experience with a welcome greeting.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="mt-3">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Smartcallingfacility} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Smart calling facility</h3>
                                    <p>IVR call system is capable to route your call as per the requirement.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </section>


            <section className=" mt-5 pt-4">
                <Container>
                    <Row className="bg">
                        <Col md={12} sm={12} lg={12} xl={6} className="mt-3" style={{'padding':'10px'}}>
                            <h2 className="white">What are you able to use IVR Automation for?</h2>
                            <h3 className="mt-4 number">Surveys for market research &amp; analysis</h3>
                            <p className="par mt-2">IVR can easily conduct an effective survey without much more effort. Also, get the real-time report and IVR is capable to perform any kind of survey in any location or demography.</p>
                            <h3 className="mt-4 number">Large-Scale Employment Drive</h3>
                            <p className="par mt-2">IVR system helps to streamline large-scale employment hiring processes. It can easily complete the screening of job seekers or candidates and save much more time &amp; effort.</p>
                            <h3 className="mt-4 number">Customer Feedback &amp; CSAT</h3>
                            <p className="par mt-2">IVR plays a very essential role in collecting valuable feedback from customers. Also, it makes customer feedback very effective &amp; easier through language choice, quick response &amp;, etc.</p>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6} className="imggg" style={{'padding':'25px','textAlign':'center'}}>
                            <img src={cuate} style={{ 'maxWidth': '77%' }} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="test">FAQs</h2>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: What are the advantages of employing IVR?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR helps organizations in automating their call handling process, decreasing wait times, and
                                        increasing customer satisfaction. Businesses may free up their agents to address more difficult
                                        enquiries by allowing callers to self-serve and easily acquire the information they require.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: How much does it cost to set up and maintain IVR?</Accordion.Header>
                                    <Accordion.Body>
                                        The cost of installing and maintaining an IVR system varies depending on the system&#39;s
                                        complexity and the vendor you use. Many businesses, however, believe that the cost savings
                                        from increased efficiency and customer satisfaction make IVR a worthwhile investment.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Do IVR systems have any legal requirements?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, IVR systems are required by law to provide opt-out options and to record consent for
                                        recorded messages. Depending on your region and the type of calls you make, you may also be
                                        required to follow legislation such as the Telephone Consumer Protection Act (TCPA) or the
                                        General Data Protection Regulation (GDPR).
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Can IVR systems be used in other businesses besides customer service?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, IVR systems can be utilised in a wide range of industries, including healthcare, finance, and
                                        retail. A healthcare provider, for example, might utilise IVR for appointment scheduling and

                                        medication refill requests, whereas a retail store might use it for order status updates and returns
                                        processing.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: What are some of the most typical issues with IVR systems?</Accordion.Header>
                                    <Accordion.Body>
                                        Long and confusing menus, technical concerns such as dropped calls or system crashes, and
                                        trouble interfacing with legacy systems are all common challenges with IVR systems. Many of
                                        these challenges, however, can be mitigated through careful planning and ongoing system
                                        monitoring and optimisation.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Do IVR systems have any limitations?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, IVR systems have limits, such as not being able to handle complex enquiries or give
                                        personalised assistance in the same manner that a live agent can. IVR systems may also
                                        struggle to grasp accents or dialects that differ from the system&#39;s standard language.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Can IVR systems be used in an emergency?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR systems may not be appropriate for emergencies requiring rapid attention. In such instances,
                                        other methods for clients to contact a live agent or emergency services are critical.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: How can I select the best IVR system for my company?</Accordion.Header>
                                    <Accordion.Body>
                                        When deciding on the best IVR system for your company, consider aspects such as the size and
                                        complexity of your company, the amount and type of calls you receive, your budget, and your
                                        specific needs and goals. You could also look into providers and read reviews from different
                                        companies to compare features and cost.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );


};
export default Ivrservice;