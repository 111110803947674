import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/manufacturing/ManufacturingIndustry.svg';
import cloudcommunication from '../../assets/manufacturing/cloudcommunication.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/manufacturing/TrackMarketingROIEffectively.svg';
import EasilyGeneraLeads from '../../assets/manufacturing/EasilyGenerateLeads.svg';
import RemoteWorking from '../../assets/manufacturing/RemoteWorking.svg';
import Securityconformity from '../../assets/manufacturing/CollectFeedback.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Manufacturing = () => {

    return (
        <>

            <Helmet>
                <title>Manufacturing Industry | Cloud Telephony Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="Transform your manufacturing business Industry with CallerSpot's cloud telephony solutions. From managing calls to enhancing customer service, our technology is designed to help you streamline operations and boost efficiency. Learn more today."

                />
            </Helmet>


            <div className="service-bannar1">
                <h1 className="education text-center text-white">Manufacturing Industry</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">

                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">How Callerspot Is Helping The Manufacturing Industry?</h2>

                                <p className="passages1 mt-3">Everything is gradually shifting to the cloud, and so is telephony. Cloud
                                    telephony involves the transfer of PBX from the office to the cloud. Cloud
                                    calling or Cloud telephony is unified communications as a service (UCaaS)
                                    that provides voice communication services via a third-party
                                    communication host. We, at Callerspot, develop a cloud telephony network
                                    that is ideal for manufacturing businesses of all sizes. Our company has
                                    been providing futuristic and easy-to-integrate communication solutions at
                                    market-leading prices.</p>

                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>

                        <Row className="reverce">
                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={cloudcommunication} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">Check out the benefits of cloud communication.</h2>
                                <ul>
                                    <li className="passages1 mt-4">Cloud telephony makes significant cost savings</li>
                                    <li className="passages1 mt-1">Cloud telephony is an Agile Telephone System that is easy to
                                        configure</li>
                                    <li className="passages1 mt-1">Cloud telephony offers scalability and increases resilience</li>
                                    <li className="passages1 mt-1">Cloud telephony ensures easy integration for parallel business growth</li>
                                    <li className="passages1 mt-1">Cloud telephony can increase productivity</li>

                                </ul>
                                <p className="passages1 mt-3">With the integration of the cloud into telephony, a crucial turning point in the
                                    evolution of communication occurred. Utilizing the cloud as a scalable and
                                    dependable platform for telephonic services allowed the manufacturing
                                    industry to benefit from the benefits of cloud computing, including cost
                                    reductions, flexibility, and accessibility.</p>

                            </Col>

                        </Row>

                    <Row className="mt-5">
                        <div className="text-center">
                            <h4 className="featured">Cloud Communication Solution for Manufacturing Industry</h4>
                            <p className="page">Improve communication, effectively handle every inquiry, and automate call and SMS handling operations.</p>
                        </div>
                    </Row>
              
                  
                        <Row className="mt-4">
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '280px'}}>
                                        <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Lead generation and sales</Card.Title>
                                            <Card.Text>
                                                With our Cloud Contact Center, Lead Management, and Missed Call
                                                Solution, you can streamline your sales and lead creation processes.
                                                Increase your sales with a focused strategy that is suited to your individual
                                                needs.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '280px'}}>
                                        <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Monitor Marketing ROI</Card.Title>
                                            <Card.Text>To measure and monitor your marketing initiatives, integrate a single multi-
                                                channel platform. For optimal effect, integrate our Missed Call Solution and
                                                Virtual Business Numbers into your online and offline marketing initiatives.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '280px'}}>
                                        <Card.Img variant="top" src={RemoteWorking} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Remote Working</Card.Title>
                                            <Card.Text>Increase your productivity with our intelligent remote work Cloud
                                                Communication Technology and Video Platform. Make yourself accessible
                                                to your consumers at all times and from any location.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '280px'}}>
                                        <Card.Img variant="top" src={Securityconformity} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Customer Service &amp; Feedback</Card.Title>
                                            <Card.Text>Provide a client-focused solution that gains every customer&#39;s trust by
                                                providing an amazing customer experience. Utilise our Video Platform,
                                                Speech Analytics, Chatbots/Voicebots, Automated Calls, SMS. and other
                                                tools to get feedback.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


        </>
    );


};
export default Manufacturing;