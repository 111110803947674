import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';

const Blog = () => {

    return (
        <>


            <Helmet>
                <title>CallerSpot Blog | Cloud Telephony & Business Communication</title>
                <meta
                    name="description"
                    content="Stay updated with the latest trends and best practices in cloud telephony and business communication with CallerSpot’s blog. Read insightful articles, tips, case studies, and more."
                />
            </Helmet>

            <div className="service-bannar">
                <Container>
                    <Row>

                        <Col md={6} sm={12} lg={6}>
                            <p className="v-text">VoIP Service</p>

                            <h6 className="s-text">There are many variations of passages of available,</h6>
                            <p class="content-text mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <div className="service-bannar-blog-img">

                            </div>

                        </Col>

                    </Row>
                </Container>


            </div>


            <section className="mt-5 pt-3">
                <Container>
                    <h2 className="s-text1 text-center">Contrary to popular belief, Lorem Ipsum is not simply random text.</h2>
                    <p className="passages text-center mt-3">There are many variations of passages of Lorem Ipsum available</p>

                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <div className="service03 mt-4">

                            </div>
                        </Col>


                        <Col md={6} sm={12} lg={6}>

                            <h3 className="text-2 mt-4">Curabitur pulvinar quam vel eros accumsan, a iaculis diam convallis.</h3>

                            <p className="passages1 mt-3">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum tristique vestibulum tristique. Curabitur eget sagittis lectus, et blandit augue.</p>
                        </Col>

                    </Row>


                </Container>

            </section>

            <Container>

                <div className="quam">
                    <Row>
                        <Col md={6} sm={12} lg={7}>

                            <h3 className="Proin mt-5">Proin at sem vitae turpis lobortis<br /> fermentum nec ac metus.<br />
                                <Button variant="danger" className="boot-buton mt-3">Get In Touch</Button>{' '}</h3>

                        </Col>
                        <Col md={6} sm={12} lg={5}></Col>
                    </Row>


                </div>


            </Container>



            <div className="deilers">
                <Container>


                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h3 className="text-2 mt-4">Proin at sem vitae turpis lobortis fermentum nec ac metus.</h3>

                            <p className="passages1 mt-3">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum tristique vestibulum tristique. Curabitur eget sagittis lectus, et blandit augue.</p>


                        </Col>


                        <Col md={6} sm={12} lg={6}>
                            <div className="service02">

                            </div>

                        </Col>

                    </Row>


                </Container>




                <section>
                    <Container className="mt-5 pt-5">

                        <Row>
                            <Col md={6} sm={12} lg={6}>
                                <div className="service01">

                                </div>
                            </Col>


                            <Col md={6} sm={12} lg={6}>

                                <h3 className="text-2 mt-4">Curabitur pulvinar quam vel eros accumsan, a iaculis diam convallis.</h3>

                                <p className="passages1 mt-3">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum tristique vestibulum tristique. Curabitur eget sagittis lectus, et blandit augue.</p>
                            </Col>

                        </Row>


                    </Container>





                </section>
            </div>
            <section>
                <Container className="tecnno">
                    <div className="display-4 text-center mt-5 pt-5 ">Our USP</div>
                    <p className="lead1 mt-3 text-center">Here are few smart features</p>

                    <Row className="bottom-nav mt-5 pt-4">
                        <Col md={6} sm={12} lg={3}>
                            <p className="voips"><img src={Image26} alt="3422" width="40" />&nbsp;IVR Service</p>
                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link href="/home" className="arc">AI Integration</Nav.Link>
                                <Nav.Link eventKey="link-1" className="arc">Google, Amazon, IBM AI Integration</Nav.Link>
                                <Nav.Link eventKey="link-2" className="arc">Agent can see customer</Nav.Link>
                                <Nav.Link eventKey="link-3" className="arc">number on incoming call</Nav.Link>
                                <Nav.Link eventKey="link-4" className="arc">CRM/Third Part Integration</Nav.Link>
                                <Nav.Link eventKey="link-5" className="arc">Quick Customization Possible</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Chat Bot Integration</Nav.Link>
                                <Nav.Link eventKey="link-7" className="arc">Free Lead Management System</Nav.Link>
                                <Nav.Link eventKey="link-8" className="arc">Marketing Automation Integration</Nav.Link>
                            </Nav>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <p className="voips"><img src={Image27} alt="3422" width="40" />&nbsp; BULK VOICE CALLS</p>

                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link href="/home" className="arc">Custom Reserved Caller ID</Nav.Link>
                                <Nav.Link eventKey="link-1" className="arc">Call Back Feature</Nav.Link>
                                <Nav.Link eventKey="link-2" className="arc">Wait till noise feature</Nav.Link>
                                <Nav.Link eventKey="link-3" className="arc">On Extension Lead</Nav.Link>
                                <Nav.Link eventKey="link-4" className="arc">On Answer SMS</Nav.Link>
                                <Nav.Link eventKey="link-5" className="arc">On Extension SMS</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">2 Way (Incoming & Outgoing)</Nav.Link>

                            </Nav>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <p className="voips"><img src={Image28} alt="3422" width="40" />&nbsp;CHAT BOT</p>
                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link href="/home" className="arc">Whatsapp and Web Support</Nav.Link>
                                <Nav.Link eventKey="link-1" className="arc">Super Easy to create and operate</Nav.Link>
                                <Nav.Link eventKey="link-2" className="arc">AI and Scripted bots</Nav.Link>
                                <Nav.Link eventKey="link-3" className="arc">Manual Agent Transfer Support</Nav.Link>
                                <Nav.Link eventKey="link-4" className="arc">Whatsapp Sharing between multiple Agents</Nav.Link>
                                <Nav.Link eventKey="link-5" className="arc">Internal Team communication inside chat</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Automatic Sales Agent Whatsapp Tracking</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Multi-Media support</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">API Support</Nav.Link>
                            </Nav>
                        </Col>
                        <Col md={6} sm={12} lg={3}>
                            <p className="voips "><img src={Image28} alt="3422" width="40" />&nbsp;AUTO DIELER</p>
                            <Nav defaultActiveKey="/home" className="flex-column">
                                <Nav.Link eventKey="link-3" className="arc">App Based Dialler so no OTC</Nav.Link>
                                <Nav.Link eventKey="link-4" className="arc">Highly Scalable</Nav.Link>
                                <Nav.Link eventKey="link-5" className="arc">Inbound and Outbound call tracking</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Two Way Call Masking</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Configure call back on masked number</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">Multiple policy assignment</Nav.Link>
                                <Nav.Link eventKey="link-6" className="arc">CRM Integrations</Nav.Link>

                            </Nav>
                        </Col>
                    </Row>

                </Container>
            </section>




        </>
    );


};
export default Blog;