import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/bank/BankingFinancialServicesimage.svg';
import cloudcommunication from '../../assets/bank/cloudcommunication.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/bank/RemoteWorking.svg';
import Securitconformity from '../../assets/bank/Securityconformity.svg';
import CollectFeedback from '../../assets/bank/CollectFeedback.svg';
import EasilyGeneraLeads from '../../assets/bank/EasilyGenerateLeads.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Banking = () => {

    return (
        <>

            <Helmet>
                <title>Cloud Telephony Solution for Financial Services Industry</title>
                <meta
                    name="description"
                    content="Transform your financial services with our cloud telephony solution. CallerSpot offers a seamless, cost-effective solution for managing your calls and customer interactions. Streamline your operations and boost customer satisfaction. Learn more now."

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Banking &amp; Financial
                    Services</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">


                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Is Callerspot Helping The Banking &amp; Financial
                                Services Industry?</h2>

                            <p className="passages1 mt-3">Cloud telephony comes with a call recording feature that stores customer communications data and uses that to monitor customer conversations. With this feature, businesses get the chance to stay compliant with customers’ lawsuits and regulatory policies. Cloud telephony offers IVR, VoIP calls, voicemail, mobile apps, call recording, sticky agents, and AI call routing</p>

                        </Col>


                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>

                    <Row className="reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={cloudcommunication} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">Check out the benefits of cloud communication.</h2>
                            <ul>
                                <li className="passages1 mt-4"> It manages international voice calls seamlessly</li>
                                <li className="passages1 mt-1">It serves customers in their local languages outside the office hours</li>
                                <li className="passages1 mt-1">  It manages calls within the CRMs</li>
                                <li className="passages1 mt-1">It redirects calls to the right departments </li>

                            </ul>
                            <p className="passages1 mt-3">
                                We, at Callerspot, develop a cloud telephony network that is ideal for Banking & Financial Services Industry. Driven by a skilled team, our company is providing reliable and futuristic communication solutions at affordable price ranges. Our offered conversational AI and Automated IVR will help customers do telebanking for card blocking, requesting cheque book, contact details update, and opening a savings account.

                            </p>

                        </Col>

                    </Row>



                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Cloud Communication Platform for the Banking and Financial Services Sector</h2>
                            <p className="page">Improve communication, respond to every client inquiry, and automate the phone and SMS processing procedure.</p>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '320px' }}>
                                    <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Lead generation and sales</Card.Title>
                                        <Card.Text>
                                            With our Cloud Contact Centre, Lead Management, and Missed Call
                                            Solution, you can streamline your sales and lead creation processes.
                                            Increase your sales with a focused strategy that is tailored to your
                                            individual requirements.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '320px' }}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Remote Working</Card.Title>
                                        <Card.Text>
                                            Increase your productivity with our intelligent remote work Cloud
                                            Communication Technology and Video Platform. Make yourself accessible
                                            to your consumers at all times and from any location.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '320px' }}>
                                    <Card.Img variant="top" src={Securitconformity} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Security and conformity</Card.Title>
                                        <Card.Text>
                                            With Call Masking and Speech Analytics on our dependable and
                                            automated Cloud-based engagement platform, you can ensure the security
                                            of your customer&#39;s trip. Our platform may be tailored to your company&#39;s
                                            exact requirements.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '320px' }}>
                                    <Card.Img variant="top" src={CollectFeedback} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Customer Service &amp; Feedback Provide</Card.Title>
                                        <Card.Text>
                                            A customer-focused solution that gains every customer&#39;s trust by providing
                                            an amazing customer experience. Utilize our Video Platform, Speech
                                            Analytics, Chatbots/Voicebots, Automated Calls, and SMS to collect
                                            feedback.
                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    );


};
export default Banking;