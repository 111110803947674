import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets//wbapi/WhatsAppBusinessAPI.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/wbapi/IVRDeflection.svg';
import SimpleAutomatedMessaging from '../../assets/wbapi/SimpleAutomatedMessaging.svg';
import ConversationalBots from '../../assets/wbapi/ConversationalBots.svg';
import SaveExpenses from '../../assets/wbapi/SaveExpenses.svg';
import FlexibilityConvenience from '../../assets/wbapi/FlexibilityConvenience.svg';
import ShareMultimediaContent from '../../assets/wbapi/ShareMultimediaContent.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/wbapi/LoadManagement.svg';
import Enhancedcustomerexperience from '../../assets/wbapi/Enhancedcustomerexperience.svg';
import BoostAgentProductivity from '../../assets/wbapi/BoostAgentProductivity.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const promotionalsms = () => {

    return (
        <>

            <Helmet>
                <title>WhatsApp Business API - Built for Enterprises| CallerSpot</title>
                <meta
                    name="description"
                    content="WhatsApp SMS: Reach your customers on the most popular messaging app with CallerSpot’s WhatsApp SMS service. Send rich media, track delivery, and get real-time feedback. Try it now!"

                />
            </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">WhatsApp Business API</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Boost Sales and Customer Satisfaction with WhatsApp Business API</p>
            </div>

            <section className="bgcolor">

            
                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">What is the WhatsApp Business API?</h2>

                                <p className="passages1 mt-3">WhatsApp Business API is a useful tool that allows businesses to efficiently connect with
                                    their clients on WhatsApp, increasing engagement for a variety of objectives, such as sales
                                    and support. WhatsApp has become a vital platform for companies, with an amazing user
                                    base of 2 billion active users, including 1 billion users that communicate with businesses
                                    regularly. Callerspot, as a renowned WhatsApp Business Solution Provider, provides a
                                    variety of benefits, such as cost savings, improved support, and greater lead generating
                                    capabilities.</p>
                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>


                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Unlock the Potential of WhatsApp for Enhanced Engagement and Operational Efficiency</h2>
                            <p className="page">By leveraging the WhatsApp Business API, businesses can deploy an end-to-end
                                communication platform that streamlines their operations and facilitates seamless customer interactions.</p>
                        </div>
                    </Row>
             
                        <Row className="mt-4">
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '260px'}}>
                                        <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>IVR Deflection</Card.Title>
                                            <Card.Text>
                                                Incoming calls may be easily redirected to WhatsApp, optimising call volumes
                                                and decreasing wait times. Improve customer satisfaction by increasing the
                                                rate of first contact resolution.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '260px'}}>
                                        <Card.Img variant="top" src={ConversationalBots} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Conversational Bots</Card.Title>
                                            <Card.Text>
                                                Use WhatsApp ChatBots to automate client engagement, quickly resolve
                                                concerns, give help, and nurture leads around the clock.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <div md={4} sm={2} lg={4}>
                                    <Card className="red" style={{'minHeight': '260px'}}>
                                        <Card.Img variant="top" src={SimpleAutomatedMessaging} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Simple Automated Messaging</Card.Title>
                                            <Card.Text>
                                                Automate personalised WhatsApp messages to keep consumers up to date
                                                on their orders, reminders, and crucial information. Bulk automation can help
                                                you streamline your message procedures.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Discover the countless advantages of deploying the WhatsApp Business API for your business.</h2>
                            <p className="pare mt-1" style={{'padding':'10px 0px','color':'#023246'}}>Embrace the power of the WhatsApp Business API and revolutionize your business communication today.</p>
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Load Management</h3>
                                    <p>Efficiently manage call traffic by shifting calls to text-based conversations on
                                        WhatsApp, reducing strain on your call center resources.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={BoostAgentProductivity} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Boost Agent Productivity</h3>
                                    <p>Enable agents to handle multiple chat conversations simultaneously, ‌increasing their
                                        productivity and optimizing resource utilization.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Enhancedcustomerexperience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Enhance Customer Experience</h3>
                                    <p>Ensure swift resolution of customer queries, minimize wait times, and elevate overall
                                        customer experience, resulting in improved CSat scores.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={SaveExpenses} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Save on Expenses</h3>
                                    <p>Reduce costs by transitioning from synchronous channels to the more economical
                                        and efficient asynchronous channel offered by WhatsApp.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ShareMultimediaContent} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Share Multimedia Content</h3>
                                    <p>Enrich customer interactions by sharing photos, documents, links, and other
                                        multimedia content, providing them with a truly immersive experience.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={FlexibilityConvenience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Flexibility and Convenience</h3>
                                    <p>Give your customers the freedom to exit and re-enter conversations at their
                                        convenience, empowering them to multitask and engage with your business anytime,
                                        anywhere.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    );



};
export default promotionalsms;