import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/tl/TransportationLogisticsIndustry.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/tl/SenderIDCustomization.svg';
import EasilyGeneraLeads from '../../assets/tl/EasilyGenerateLeads.svg';
import TrackMarketingROIEffectively from '../../assets/tl/TrackMarketingROIEffectively.svg';
import RemoteWorking from '../../assets/tl/RemoteWorking.svg';
import Securityconformity from '../../assets/tl/CollectFeedback.svg';
import Securitconformity from '../../assets/tl/Securityandconformity.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const TransportationLogistics = () => {

    return (
        <>

            <Helmet>
                <title>Transportation Logistics Industry | Cloud Telephony Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="Streamline your transportation logistics industry with CallerSpot's cloud telephony solutions. From real-time tracking to automated notifications, our platform helps you stay connected and in control. Learn more about our innovative technology today."

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Transportation & Logistics Industry</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">

                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Callerspot Is Helping The Transportation & Logistics Industry?</h2>

                            <p className="passages1 mt-3">Give your users a new experience of information sharing and innovative cloud communication technology! Callerspot is a cloud telephony company that is committed to helping the Logistics Industry businesses in fulfilling the customer requirements at the best. Our team ensures maximum privacy, drives work efficiency, and integrates advanced business communication tools for addressing specific consumer needs. </p>

                            <ul>
                                <li className="passages1">It helps logistics companies to change the dynamics of business by real-time updates</li>
                                <li className="passages1"> It helps in dealing with the third-party logistics partners efficiently</li>
                                <li className="passages1">It helps in dealing with the exact user needs by the integration of communication tools</li>
                                <li className="passages1"> It helps the companies to replace the human errors like wrong inputs</li>
                                <li className="passages1">  It helps the customers to get update on possible delivery delays</li>
                            </ul>
                            <p className="passages1">Cloud calling is an integrated communication system as a service (UCaaS) that is engaged in providing voice communication services through a third-party host. We will integrate the cloud-based communication tools to help the companies in reducing the human errors, such as wrong data input, misplaced data, or delay in message delivery. </p>

                        </Col>


                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>

                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">We Sufficient for the Logistics Industry</h2>
                            <p className="page">You can create an efficient and dynamic consumer base with a strong
                                communication channel</p>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Sales and Lead Generation Made Easy</Card.Title>
                                        <Card.Text>
                                            Organizing the sales and lead generation process with our Cloud Contact
                                            Center, Lead Management, and Missed Call Solution. Boost sales with a
                                            targeted approach tailored to meet your specific requirements.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={TrackMarketingROIEffectively} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Track Marketing ROI Effectively</Card.Title>
                                        <Card.Text>Integrate a unified multi-channel platform to track and monitor your online
                                            and offline marketing campaigns. Connect Missed Call Solution and Virtual
                                            Business Numbers to gain valuable insights into your marketing ROI.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={RemoteWorking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Enhance Remote Working Capabilities</Card.Title>
                                        <Card.Text>Empower your team with a smart remote work Cloud Communication
                                            Technology and Video Platform, allowing them to be available to your
                                            customers from anywhere, anytime.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={Securitconformity} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Prioritize Security &amp; Compliance</Card.Title>
                                        <Card.Text>Ensure your customer’s journey is secure with Call Masking and Speech
                                            Analytics. Our reliable and automated Cloud-based engagement platform
                                            can be customized to align with your business needs.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Optimize Logistics &amp; Delivery Operations</Card.Title>
                                        <Card.Text>Equip your team with a smart platform to keep your customers informed
                                            through their preferred channel of communication. Configure multi-level
                                            IVR to enable real-time tracking of every order.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{ 'minHeight': '281px' }}>
                                    <Card.Img variant="top" src={Securityconformity} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Deliver Exceptional Customer Support &amp; Gather Feedback</Card.Title>
                                        <Card.Text>Win your customer’s trust by delivering a refined customer experience and
                                            collecting feedback through Chatbots/Voicebots, Automated Calls, and
                                            SMS, all backed by advanced Speech Analytics.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    );

};
export default TransportationLogistics;