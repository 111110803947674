import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/vn/VirtualNumber.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import GlobalReach from '../../assets/vn/GlobalReach.svg';
import CallRouting from '../../assets/vn/CallRouting.svg';
import PrivacyGuard from '../../assets/vn/PrivacyGuard.svg';
import AnalyticsInsights from '../../assets/vn/AnalyticsInsights.svg';
import VoicemailTexting from '../../assets/vn/VoicemailTexting.svg';
import ScalableSolution from '../../assets/vn/ScalableSolution.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import CostEfficiency from '../../assets/vn/CostEfficiency.svg';
import EnhancedCredibility from '../../assets/vn/EnhancedCredibility.svg';
import FlexibilityMobility from '../../assets/vn/FlexibilityMobility.svg';
import CallTracking from '../../assets/vn/CallTracking.svg';
import CustomerConvenience from '../../assets/vn/CustomerConvenience.svg';
import DataDrivenDecisions from '../../assets/vn/DataDrivenDecisions.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <>

            <Helmet>
                <title>
                Virtual Number Solutions | Virtual Phone Number India | CallerSpot
                </title>
                <meta
                    name="description"
                    content="Enjoy the benefits of a virtual mobile number with India's leading virtual number solution provider - CallerSpot. Get a demo for your business today!"
                />

            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white pt-5">Virtual Number</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">
                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <h2 className="text-2">What Is A Virtual Number?</h2>
                            <p className="passages1 mt-3"> A virtual Number is just a phone number of the telephone that is not connected or related to the physical location. Users can use virtual phone numbers for calling purposes from anytime from any location. It gives extreme privacy as well as anonymity from traditional phone lines.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5">
                            <img src={Rectangle} style={{ 'width': '100%', 'maxHeight': '85%' }} />
                        </Col>
                    </Row>
                    <Row>
                        <div className="text-center">
                            <h2 className="featured">Features of Virtual Numbers</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <Col className="mt-3" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={GlobalReach} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Global Reach</Card.Title>
                                    <Card.Text>
                                        Obtain local numbers worldwide for improved international communication.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="mt-3" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Call Routing</Card.Title>
                                    <Card.Text>
                                        Easily redirect calls to different devices or numbers for efficient handling
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="mt-3" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={PrivacyGuard} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Privacy Guard</Card.Title>
                                    <Card.Text>
                                        Keep personal numbers private while maintaining professional communication.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={AnalyticsInsights} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Analytics Insights</Card.Title>
                                    <Card.Text>
                                        Access call data and patterns to enhance business strategies and customer interactions.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={VoicemailTexting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Voicemail & Texting</Card.Title>
                                    <Card.Text>
                                        Receive voicemails and texts, ensuring you never miss important messages
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>
                            <Card className="red" style={{'minHeight': '224px'}}>
                                <Card.Img variant="top" src={ScalableSolution} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>  Scalable Solution</Card.Title>
                                    <Card.Text>
                                        Scale your virtual number usage as your business expands without physical limitations.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Virtual Numbers</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CostEfficiency} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Cost-Efficiency </h3>
                                    <p>Reduce expenses by avoiding multiple physical phone lines for various locations.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={EnhancedCredibility} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Enhanced Credibility</h3>
                                    <p>Establish local presence, boosting trust and rapport with regional customers,</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={FlexibilityMobility} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Flexibility & Mobility</h3>
                                    <p> Manage calls on-the-go, enabling remote work and increased responsiveness</p>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CallTracking} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Call Tracking</h3>
                                    <p>Monitor marketing campaigns by assigning unique numbers, gauging their effectiveness</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CustomerConvenience} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Customer Convenience</h3>
                                    <p>Allow clients to reach you easily with recognizable local numbers they trust.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={DataDrivenDecisions} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Data-Driven Decisions</h3>
                                    <p>Utilize call analytics to refine customer interactions and optimize operations.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>


            <section className=" mt-5 pt-4">
                <Container>
                    <Row className="bg">
                        <Col md={12} sm={12} lg={12} xl={6} style={{'padding':'70px'}}>
                            <h2 className="white mt-3">Solutions of the Virtual Number:</h2>
                            <h3 className="mt-4 number">Get seamless integration with CRM software.</h3>
                            <p className="par mt-2">Handle a large volume of calls.</p>
                            <h3 className="mt-4 number">Get complete tracking & analytics from the depth.Activate</h3>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6} className="text-center" >
                            <img src={cuate} style={{ 'maxWidth': '100%' }} />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/*         

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                </Container>
            </section> */}

        </>
    );


};
export default Virtual;