import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import ClicktoCallServicefirst from '../../assets/ctc/ClicktoCallServicefirst.svg';
import ClicktoCallServicesec from '../../assets/ctc/ClicktoCallServicesec.svg';
import CallRouting from '../../assets/ctc/CrystalclearAudio.svg';
import InteractiveVoiceResponseIVRsystem from '../../assets/ctc/InteractiveVoiceResponseIVRsystem.svg';
import MultideviceCompatibility from '../../assets/ctc/MultideviceCompatibility.svg';
import RecordingofPhoneCalls from '../../assets/ctc/RecordingofPhoneCalls.svg';
import Nonumbersdialling from '../../assets/ctc/Nonumbersdialling.svg';
import CRMintegration from '../../assets/ctc/CRMintegration.svg';
import CallerID from '../../assets/ctc/CallerID.svg';
import Simpleconversation from '../../assets/ctc/Simpleconversation.svg';
import CallbackAPI from '../../assets/ctc/CallbackAPI.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/ctc/CaptureWebsiteVisitors.svg';
import ReduceCallWaitingTime from '../../assets/ctc/ReduceCallWaitingTime.svg';
import CallWithoutDialing from '../../assets/ctc/CallWithoutDialing.svg';
import EasyImplementation from '../../assets/ctc/EasyImplementation.svg';
import TrackConversation from '../../assets/ctc/TrackConversation.svg';
import Enhancedcustomerexperience from '../../assets/ctc/Enhancedcustomerexperience.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const ClicktoCall = () => {

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <>


            <Helmet>
                <title>
                Click to Call Solution | Click to Call Service| CallerSpot
                </title>
                <meta
                    name="description"
                    content="Give customers a chance to connect through a click to call button on your website. Connect with CallerSpot and avail this web calling solution today."
                />

            </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">Click to Call Service</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Discover the Power of Click to Call
                    Service for Clear and Reliable
                    Conversations</p>
            </div>

            <section className="bgcolor">
                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is click to call?</h2>

                            <p className="passages1 mt-3">Click to call is one of the advance technologies that can quickly be
                                integrated into any of the websites or any of the mobile app which acts as a
                                calling widget for the customers so that the business can be reached
                                quickly and instantly. It acts as an tool which helps to enhance the business
                                exponentially by facilitating one to one business communication between
                                the customers and business.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={ClicktoCallServicefirst} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>
                    <Row className="mt-4 reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={ClicktoCallServicesec} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How click to call service works?</h2>

                            <p className="passages1 mt-3">After having gained the understanding of click to call service , majority of
                                them would want to understand the working functionality of it before going
                                ahead with the implementation process. In case you are one of them then
                                read through the instructions of this tutorial and then you will certainly get a
                                good understanding of the same. In case not then do contact us and we
                                would be happy to offer you the assistance and resolve the queries at any
                                given point of time all through the day and all through the year.</p>


                        </Col>


                    </Row>

                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Features of Callerspot’s Click to Call Service</h2>
                            {/* <p className="page">IVRs make any business comfortable, easier &amp; simplest to their customers or users.</p> */}
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={InteractiveVoiceResponseIVRsystem} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>IRV Menu</Card.Title>
                                        <Card.Text>
                                            Answer every consumer enquiry using the IVR&#39;s multi-
                                            level menu to build direct engagement with your customer.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Crystal-clear Audio</Card.Title>
                                        <Card.Text>
                                            Enjoy clear and crisp voice quality during calls,
                                            minimizing distortion and background noise for improved
                                            communication.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={MultideviceCompatibility} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Multi-device Compatibility</Card.Title>
                                        <Card.Text>
                                            Customers may utilise the callback
                                            button on multiple devices, downloading no additional plugins.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={RecordingofPhoneCalls} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Call recording</Card.Title>
                                        <Card.Text>
                                            Call recordings can help you make informed
                                            decisions.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={Nonumbersdialling} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>No numbers dialling</Card.Title>
                                        <Card.Text>
                                            Agents can contact your consumer with a few
                                            mouse clicks. It may reach more clients in less time.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={CRMintegration} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>CRM integration</Card.Title>
                                        <Card.Text>
                                            Integrate effortlessly with all of the market&#39;s main
                                            CRMs.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={CallbackAPI} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Callback API</Card.Title>
                                        <Card.Text>
                                            Incorporate callback APIs into your agent portal or
                                            app. And, with no manual dialling, instantly connect with the target
                                            consumer.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={CallerID} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Caller ID</Card.Title>
                                        <Card.Text>
                                            Identify incoming callers with the help of caller ID
                                            information, allowing you to prioritize and personalize your
                                            responses.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '240px'}}>
                                    <Card.Img variant="top" src={Simpleconversation} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Simple conversation</Card.Title>
                                        <Card.Text>
                                            Keep note of your customers&#39;
                                            communications, reasons for calling, and what is said during the
                                            conversation.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Caller’s Spot Click to Call Solution</h2>

                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Capture Website Visitors</h3>
                                    <p>Get rid of long call queues and deliver a
                                        high-quality customer experience by asking customers to
                                        request/schedule a callback at their convenience.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ReduceCallWaitingTime} style={{height:'46px'}}/>
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Reduce Call Waiting Time</h3>
                                    <p>Remove the need to remember and
                                        manually dial numbers. You may call the specified number with a
                                        single click on the browser..</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CallWithoutDialing} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Call Without Dialing</h3>
                                    <p>Eliminate ‌remembering and manually dialling
                                        numbers. With a single click on the browser, you can place the call
                                        on the desired number.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Enhancedcustomerexperience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Better Customer Experience</h3>
                                    <p>Providing a quick and efficient mode
                                        of contact decreases the customer&#39;s effort to establish a dialogue
                                        with the company.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={EasyImplementation} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Easy Implementation</h3>
                                    <p>There is no need for complicated
                                        configuration or deployment. A simple and easy-to-integrate calling
                                        widget may be placed on your website or portal immediately.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={TrackConversation} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Track Conversation</h3>
                                    <p>Listen to call recordings and analyse call
                                        trends to determine consumer behavioural patterns and expectations.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Container>
            </section>


            <section className="mt-5 pt-4">
                <Container>
                    <Row className="bg">
                        <Col md={12} sm={12} lg={12} xl={6} style={{'padding':'70px'}}>
                            <h2 className="white mt-3">Steps to explain the functionality of click to call</h2>
                            {/* <h4 className="mt-4 number">Surveys for market research &amp; analysis</h4> */}
                            <p className="par mt-2"> <strong>Step 1: </strong> The process starts with integrating API system connecting the
                                customers to the company representatives.</p>
                            {/* <h4 className="mt-4 number">Large-Scale Employment Drive</h4> */}
                            <p className="par mt-4"> <strong>Step 2:</strong> Customers would then need to click on the calling widget which will
                                then ask the customers to provide the number.</p>
                            {/* <h4 className="mt-4 number">Customer Feedback &amp; CSAT</h4> */}
                            <p className="par mt-4"> <strong>Step 3:</strong> By doing so, API is configured to redirect the customers calls via
                                the VOIP to the company.</p>
                            <p className="par mt-4"> <strong>Step 4:</strong> Finally, customers would receive the call as soon as the widget
                                gets connected which marks the closure of the functionality.</p>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6} className="imggg" style={{'textAlign':'center'}}>
                            <img src={cuate} className="w-100" style={{ 'maxWidth': '74%' }} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="test">FAQs</h2>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. Does it also function as a click-to-connect solution?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, Callerspot is a click-to-connect service that allows people to quickly
                                        connect with your firm.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How can I configure the service for my company?</Accordion.Header>
                                    <Accordion.Body>
                                        To configure the service for your business, go to the Callerspot website and
                                        follow the step-by-step instructions.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How does Callerspot plan to provide me with the service?</Accordion.Header>
                                    <Accordion.Body>
                                        Callerspot intends to serve you by providing a user-friendly platform with
                                        smooth integration possibilities and attentive customer support.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How long will it take to establish the service?</Accordion.Header>
                                    <Accordion.Body>
                                        The time necessary to set up the service can vary based on your
                                        company&#39;s unique needs, but Callerspot strives to make the process as
                                        quick as possible.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What if this functionality isn&#39;t right for my company?</Accordion.Header>
                                    <Accordion.Body>
                                        If the functionality isn&#39;t right for your business, Callerspot provides
                                        customization choices and adaptable features to meet your needs.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                    </Row>

                </Container>
            </section>

        </>
    );


};
export default ClicktoCall;