import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import 'react-phone-number-input/style.css'
import '../../../node_modules/react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Container, Row, Col, Tab, Nav, Card, Button, Form } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import Image35 from '../../assets/call-icon.png';
import Image36 from '../../assets/mail.png';
import Image37 from '../../assets/home-1.png';

const Callto = ({ phone, children }) => {
    return (
        <a className="tel" href={`tel:${phone}`}>
            {children}
        </a>
    );
};
function Mailto({ email, subject, body, ...props }) {
    return (
        <a
            className="tel"
            href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
        >
            {props.children}
        </a>
    );
}


const Contact = () => {
    const [phone, setPhone] = useState("");


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_ug0umhk",
                "template_dl8ruhs",
                form.current,
                "qgwqpK8jkfC37OZJD"
            )
            .then(
                (result) => {
                    console.log(result);
                    const show = document.getElementById("messages");
                    show.innerHTML = " Your Response has been sent. &#128077; ";
                    form.current.reset();
                    setPhone("");
                    setTimeout(() => {
                  }, 10000);
                  },
                (error) => {
                    console.log(error.text);
                }
            );
       
    };

    return (
        <>



            <Helmet>
                <title> Contact Us | CallerSpot - Get in Touch with Our Experts</title>
                <meta
                    name="description"
                    content="Contact Callerspot's team for any inquiries, questions or concerns about our communication and collaboration platform. Our experts are here to help you enhance your team's productivity and efficiency."

                />
            </Helmet>

            <section className="service-bannar">
                <Container>
                    <Row>

                        <Col md={6} sm={12} lg={6}>
                            <p className="v-text"></p>

                            <h1 className="s-text">Connect with Callerspot Customer Support for Fast and Friendly Assistance</h1>
                            <p class="content-text mt-3">You don’t need to check the time before reaching out to us - our enhanced customer support team is available to assist you with any questions or concerns you may have. Contact us anytime for the support you need.</p>
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <div className="service-bannar-contact-img">

                            </div>

                        </Col>

                    </Row>
                </Container>


            </section>


            <section>
                <Container>
                    <Row>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className=" h4 mt-5">Get in Touch With Us. For all enquiries, Email us</h2>
                            <div className=" mt-4">For all enquiries, please use the form below to email us. Our team is here to help you with any questions or concerns you may have.</div>
                            <p className=" h4 mt-5"><img src={Image35} alt="3422" width="60" />&ensp;<Callto phone="+919711665756">+919711665756</Callto></p>
                            {/* <p className="h4"> &emsp;&emsp;&emsp;</p> */}
                            <div className="h4 mt-4"><img src={Image36} alt="3422" width="60" />&ensp;
                                <Mailto
                                    email="info@callerspot.com"
                                    subject="Hello"
                                    body="Hello world!">
                                    info@callerspot.com
                                </Mailto></div><br />
                            {/* <p className="h4">&emsp;&emsp;&emsp;</p> */}
                            <div className="h4" style={{ 'display': 'flex' }}><img src={Image37} alt="3422" width="60" height='60' /><ul style={{ 'listStyle': 'none', 'paddingLeft': '12px' }}>
                                <li>C-56/A3 Ground Floor, Sector 62, Noida, Uttar Pradesh 201301 </li>
                            </ul></div>
                            {/* <p className="h4">&emsp;&emsp;&emsp;</p> */}

                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <div className="form-background">

                                <Form ref={form} onSubmit={sendEmail}>
                                    <Form.Label>Enter Your Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="to_name"
                                        placeholder="Name"

                                    />
                                    <br />

                                    <Form.Label>Enter Your Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Email"
                                        name="to_email"
                                    />
                                    <br />

                                    <Form.Label>Enter Your Number</Form.Label>
                                    <PhoneInput className=""
                                        placeholder="Enter First Country Number"
                                        name="to_number"
                                        value={phone}
                                        maxlength="15"
                                        onChange={(phone) => setPhone(phone)}  />

                                    <br />

                                    <Form.Label>Entre Your Message</Form.Label>

                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        name="message"
                                        placeholder="Write your message"
                                    />


                                    <br />
                                    <span id="messages" className="message-sent" style={{color:'red'}}></span>

                                    <Button variant="secondary" className="submit-button" size="lg" type="submit" value="Send">
                                        Submit
                                    </Button>
                                </Form>

                            </div>
                        </Col>
                    </Row>

                </Container>


            </section>


        </>

    );


};

export default Contact;