import { Button } from "react-bootstrap";

export const Items = [

    {
      title: 'Product',
      submenu: [
  
        {
          title: 'Voice',
          submenu: [
            {
              title: 'Call Tracking',
              url: '/call-tracking',
  
            },
            {
              title: 'Cloud Contact Center',
              url: '/cloud-center',
  
            },
            {
              title: 'Virtual Number',
              url: '/virtual-number',
  
            },
            {
              title: 'IVR Service',
              url: '/ivr-service',
            },
            {
              title: 'Number Masking',
              url: '/number-masking',
            },
            {
              title: 'Lead Management',
              url: '/lead-management',
            },
            {
              title: 'Missed Call',
              url: '/missed-call',
            },
            {
              title: 'Toll Free Number',
              url: '/toll-free-number',
            },
            {
              title: 'Automated Outbond Calling',
              url: '/automated-outbond-calling',
            },
            {
              title: 'Click to Call',
              url: '/click-to-call',
            },
            // {
            //   title: 'SoftPhone with WebRTC with MPLS',
            //   url: '/SoftPhone-with-WebRTC-with-mpls',
            // },
  
  
            // {
            //   title: 'True Caller',
            //   url: '/true-Caller',
            // },
  
  
            {
              title: 'Auto Dialer',
              url: '/auto-dialer',
            },
  
          ],
        },
  
  
        {
          title: 'SMS',
          // url: 'Messaging',
          submenu: [
            {
              title: 'Promotional SMS',
              url: 'promotional-sms',
            },
  
            {
              title: 'Transactional SMS',
              url: '/transactional-sms',
            },
            {
              title: 'WhatsApp Business API',
              url: 'whatsapp-business-api',
            },
  
  
          ],
        },
  
        // {
        //   title: 'SEO',
        //   url: 'seo',
        // },
  
  
  
        // {
        //   title: 'Automation',
        //   url: '/Video',
  
        // },
  
  
        {
          title: 'Automation',
          // url: '/artificial-intelligence',
          
          submenu: [
            {
              title: 'Text-To-Speech',
              url: '/text-to-speech',
            },
  
            {
              title: 'AI ChatBot/VoiceBot',
              url: '/all-chatBot',
            },

            {
              title: 'Speech Analytics',
              url: '/speech-analytics',
            },
  
  
                        // {
            //   title: 'Backend',
            //   submenu: [
            //     {
            //       title: 'NodeJS',
            //       url: 'node',
            //     },
            //     {
            //       title: 'PHP',
            //       url: 'php',
            //     },
            //   ],
            // },
          ],
        },
        // {
        //   title: 'SEO',
        //   url: 'seo',
        // },
      ],
    },
  
  
    {
      title: 'Solutions',
      submenu: [
  
        {
          title: 'Industry',
          url: '',
          submenu: [
            {
              title: 'Banking & Finacial',
              url: 'banking',
            },

            // {
            //   title: 'Vertual Number',
            //   url: 'vertual',
            // },
            // {
            //   title: 'IT & ITS',
            //   url: 'its',
            // },
            {
              title: 'Education',
              url: 'education',
            },

            {
              title: 'Healthcare',
              url: 'healthcare',
            },


            {
              title: ' Retail & Ecommerce',
              url: 'retail-ecommerce',
            },

            // {
            //   title: ' Finance',
            //   url: 'finance',
            // },

            {
              title:'Transportation & Logistics',
              url: 'transportation-logistics',
            },
           

            {
              title:'Manufacturing',
              url: 'manufacturing',
            },

            // {
            //   title:'Public Sector',
            //   url: 'public-sector',
            // },



            
          ],
        },
  
  
        // {
        //   title: 'By Need',
        //   url: 'By-Need',
        //   submenu: [
        //     {
        //       title: 'Sales & Lead Generation',
        //       url: 'Sales-lead-generation',
        //     },

        //     {
        //       title: 'Track Marketing ROI',
        //       url: 'track-marketing',
        //     },
        //     {
        //       title: 'Remote Work Solution',
        //       url: 'remotework-work-solution',
        //     },

        //     {
        //       title: 'Customer Service & Support',
        //       url: 'customer-service-support',
        //     },

        //     {
        //       title: 'Logstics & Delivery',
        //       url: 'logstics-delivery',
        //     },

        //     {
        //       title: 'Automated Coll Service',
        //       url: 'automated-coll-service',
        //     },
        //     {
        //       title: 'Security & Compliance',
        //       url: 'security-comoliance',
        //     },

            
        //     {
        //       title: 'Backend',
        //       submenu: [
        //         {
        //           title: 'NodeJS',
        //           url: 'node',
        //         },
        //         {
        //           title: 'PHP',
        //           url: 'php',
        //         },
        //       ],
        //     },
        //   ],
        // },
  
  
  
  
  
  
        // {
        //   title: 'SEO',
        //   url: 'seo',
        // },
      ],
    },


    {
      title: 'About Us',
      url: '/about-us',
     
    },
  

    {
      title: 'Contact Us',
      url: '/contact-us',
     
    },

    {
      title: 'Pricing',
      url: '/pricing',
     
    },


    // {
    //   title: 'Company',
    //   url: '/Company',
    //   submenu: [
  
    //     // {
    //     //   title: 'About US',
    //     //   url: '/about-us',
         
    //     // },
  
        

    //     // {
    //     //   title: 'Careers',
    //     //   url: '/career',
         
    //     // },


    //     // {
    //     //   title: 'Terms & Condition',
    //     //   url: '/career',
         
    //     // },

    //     // {
    //     //   title: 'Privacy policy',
    //     //   url: '/privacy-policy',
         
    //     // },

       
  
  
  
  
  
      
  
       
    //   ],
    // },
  
    // {
    //   title: 'Resources',
    //   url: '/Resources',
    //   submenu: [
  
    //     // {
    //     //   title: 'Webinars',
    //     //   url: 'webinar',
        
    //     // },
  
    //     {
    //       title: 'Blog',
    //       url: 'blog',
        
    //     },
    //     // {
    //     //   title: 'News & Events',
    //     //   url: 'news-events',
        
    //     // },
    //     // {
    //     //   title: 'Customers & Stories',
    //     //   url: 'customer-stories',
        
    //     // },
  
        
  
  
  
  
        
  
    //     // {
    //     //   title: 'SEO',
    //     //   url: 'seo',
    //     // },
    //   ],
    // },
  
  
  
    // {
    //   title:'Terms and Condition',
    //   url: 'term-and-condition',
    // },
    // {
    //   title:'Privacy Policy',
    //   url: 'privacy-policy',
    // },
  
  
    // {
    //   title: 'About',
    //   url: '/about',
    //   submenu: [
    //     {
    //       title: 'Who we are',
    //       url: 'who-we-are',
    //     },
    //     {
    //       title: 'Our values',
    //       url: 'our-values',
    //     },
    //   ],
    // },
  ];
  