import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button, Carousel } from "react-bootstrap";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import index from '../CallTracking/index.css';
import Rectangle from '../../assets/bro.svg';
import telphone from '../../assets/001-telephone.png';
import call from '../../assets/002-call.png';
import device from '../../assets/005-any-device.png';
import invisible from '../../assets/006-invisible.png';
import application from '../../assets/004-application.png';
import search from '../../assets/003-search-file.png';
import demo_title_img from '../../assets/demo_title_img.png';
import easy from '../../assets/001-easy.png';
import tasks from '../../assets/002-tasks.png';
import cloud from '../../assets/003-cloud.png';
import customer from '../../assets/004-customer-review.png';
import support from '../../assets/005-support-services.png';
import budgeting from '../../assets/006-budgeting.png';
import logo from '../../assets/logo-1.svg';
import logo2 from '../../assets/logo-2.svg';
import logo3 from '../../assets/logo-3.svg';
import star from '../../assets/star-1.svg';
import star2 from '../../assets/star-2.svg';
import star3 from '../../assets/star-3.svg';
import Accordion from 'react-bootstrap/Accordion';
import about from '../../assets/about1.png';


const CloudCenter = () => {

    return (
        <>

            <Helmet>
                <title>Call Center Software | Cloud Call Center Solution | CallerSpot</title>
                <meta name="description" content="Cloud call center software helps to handle the large volume of calls. Callerspot provides cloud contact center solutions to business to improve customer experience."/>
                <link rel="canonical" href="http://localhost:3000/call-tracking" />
            </Helmet>

            <section className="back-section">
                <Container className="pt-5">
                    <Row className="pt-5 section-1">
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <div className="mt-4">
                                <h1>Phone Calls Tracking App</h1>
                            </div>
                            <div className="mt-4">
                                <p>Get remote access to all call logs!</p>
                            </div>
                            <div className="but-2 mt-4">
                                <button className="btntry">Try Now</button>
                            </div>
                            <div className="mt-4">
                                <p className="pt-3"> No.1 Choice in India</p>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>

            
                                <Carousel>
                                    <Carousel.Item interval={1500}>
                                        <img src={Rectangle} style={{'width':'100%'}} />
                                    </Carousel.Item>
                                    <Carousel.Item interval={500}>
                                        <img src={Rectangle} style={{'width':'100%'}} />
                                    </Carousel.Item>
                                </Carousel>
                      
                        </Col>
                    </Row>
                </Container>

                <Container className="pb-5">
                    <Row className="mt-5">
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={telphone} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>View calls</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">All incoming, outgoing or deleted call logs</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={call} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>See callers</h5>
                                            <p style={{ textAlign: 'left', }} className="mt-2">All caller details and information from the address book</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={search} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Know details</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">Get exact timestamp and details of every call. Sometimes it's very important.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Row className="mt-4"></Row>

                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={application} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Get more</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">SMS and text messages, GPS locations and routes, WhatsApp, Snapchat, Instagram, Facebook, Telegram, and other data.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={device} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Any device</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">Don't worry about the target device’s operating system. All iPhone, iPad, or Android devices are supported.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={invisible} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Stay invisible</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">Nobody will know about your actions. You will remain 100% invisible. No app icon will be displayed!</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>

            <section className="section-3bg pt-5 pb-5">
                <Container className="section-4 mt-5 pb-5 text-center">
                    <h2> Control panel preview</h2>
                    <p>Click on the image to see the demo</p>
                    <div className="mt-5">
                        <img src={demo_title_img} alt="" style={{ 'max-width': '100%' }} />
                    </div>
                    <div className="but-2 mt-5">
                        <button className="btntry">Try Now</button>
                    </div>
                </Container>
            </section>

            <section className="section-5bg pt-5 pb-5">
                <Container className="pt-5 pb-5">
                    <div className="text-center">
                        <h2>Advantages</h2>
                        <p>Here are a few more points why you should choose callerSpot </p>
                    </div>
                    <Row className="mt-5">
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={easy} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Easy install</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">It takes up to 5 minutes to set up the app. No jailbreak or root required*</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={tasks} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Real-time updates</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">Information about all activities on the device updates every 5 min remotely.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={cloud} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Protect data</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">All information is securely stored and protected. Only you have access to it.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Row className="mt-4"></Row>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={customer} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Top service</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">87% of our users are totally satisfied.</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={support} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>24/7 support</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">If you need assistance, we are happy to help at any time!</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mt-2">
                            <div id="mycard">
                                <Card>
                                    <Card.Body className="d-flex" style={{ 'padding': '17px' }}>
                                        <div className="icon">
                                            <img src={budgeting} style={{ width: '20px' }} />
                                        </div>&ensp;&emsp;
                                        <div>
                                            <h5 style={{ textAlign: 'left' }}>Budget friendly</h5>
                                            <p style={{ textAlign: 'left' }} className="mt-2">The price is less than $1 per day!</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <div className="but-2 mt-5 text-center">
                        <button className="btntry">Try Now</button>
                    </div>
                </Container>
            </section>

            <section className="section-6bg  pt-5 pb-5">
                <Container className="section-6 text-center pt-5 pb-5">
                    <h2>Real Reviews</h2>
                    <p className="mt-2">CallerSpot rated great on trusted review platforms and independent media</p>
                    <Row className=" pt-5">
                        <Col md={4} className="logoss">
                            <div className="mt-5">
                                <img src={logo} alt="" style={{ 'max-width': '100%', 'height': '35px' }} />
                            </div>
                            <div className="mt-3">
                                <img src={star} alt="" style={{ 'max-width': '100%' }} />
                            </div>
                            <p className="mt-2">Based on 2,291 reviews</p>
                        </Col>
                        <Col md={4} className="logoss">
                            <div className="mt-5">
                                <img src={logo2} alt="" style={{ 'max-width': '100%', 'height': '35px' }} />
                            </div>
                            <div className="mt-3">
                                <img src={star2} alt="" style={{ 'max-width': '100%' }} />
                            </div>
                            <p className="mt-2">Based on 2,291 reviews</p>
                        </Col>
                        <Col md={4} className="logoss">
                            <div className="mt-5">
                                <img src={logo3} alt="" style={{ 'max-width': '100%', 'height': '35px' }} />
                            </div>
                            <div className="mt-3">
                                <img src={star3} alt="" style={{ 'max-width': '100%' }} />
                            </div>
                            <p className="mt-2">Based on 2,291 reviews</p>
                        </Col>
                    </Row>

                    {/* <Row className="mt-5">
                        <Col md={10}>
                            <OwlCarousel className='owl-theme' loop margin={1} nav {...options}>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>Best monitoring service</h3>
                                        <p className="">I have been using this service past 3 months now Found extremely useful and
                                            got 100% satisfaction from this service. I thank Alex Scarn for giving me immense
                                            support for this service </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>It is a must for parents of teenagers</h3>
                                        <p className="">As a parent of a teen, I understand how difficult it can get to keep tabs on
                                            their lives. This app has been an absolute lifesaver - not only am I able to have more
                                            control .</p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>Nice App</h3>
                                        <p className=""> I was looking for a way to monitor a target device silently and I found
                                            CallerSpot helpful for it. </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>CallerSpot experience</h3>
                                        <p className="">CallerSpot has worked decently well for me. However, there have been some
                                            problems with the data uploading slowly</p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>Good once i figured it all out</h3>
                                        <p className="">Informative & a bit complicated to get up & going but overall great! I am
                                            very pleased with all the things this app can monitor.

                                        </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>Perfect application</h3>
                                        <p className="">Perfect application to monitor your children. TOP support. There is Alex
                                            Scott who has assisted me multiple times throughout the day even at inconvenient times.
                                        </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>It keeps my kids safe online</h3>
                                        <p className="">The fact of being able to count on GPS and knowing the location of the children in each
                                            moment is an added value for this application. </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>Best monitoring service</h3>
                                        <p className="">I have been using this service past 3 months now Found extremely useful and
                                            got 100% satisfaction from this service. I thank Alex Scarn for giving me immense
                                            support for this service

                                        </p>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="card-align">
                                        <h3>It is a must for parents of teenagers</h3>
                                        <p className="">As a parent of a teen, I understand how difficult it can get to keep tabs on
                                            their lives. This app has been an absolute lifesaver - not only am I able to have more
                                            control .
                                        </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row> */}

                </Container>
            </section>


            <section className="section-7bg pt-5 pb-5">
                <Container className="pt-5 pb-5 text-center">
                    <div>
                        <h2>Compatibility</h2>
                        <p className="mt-3">Enter the model of the phone or tablet to check its compatibility. Just to be sure</p>
                    </div>
                    <Row className="mt-5">
                        <Col md={5} className="offset-md-2 form-box mt-2">
                            <input className="form-control" type="text" placeholder="  for example, iphone 8" />
                        </Col>
                        <Col md={3} className="mt-2">
                            <div className="but-2">
                                <button className="btntry">check compatibility</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-8bg  pb-5 pt-5">
                <Container className="section-8 text-center pt-5 pb-5">
                    <h2>Get Started In 3 Easy Steps</h2>
                    <p className="mt-2">Really easy</p>
                    <Row className="contenter mt-5">
                        <Col lg={12} md={12} sm={12} className="mb-3">
                            <div className="easy__header">
                                <div className="easy__header--item1">
                                    <span>1</span>
                                </div>
                                <div className="easy__header--item2">
                                    <span>2</span>
                                </div>
                                <div className="easy__header--item3">
                                    <span>3</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <h3>Create your free account</h3>
                            <p>It’s as easy as entering your email. We’ll set you up with your own callerSpot account, so you’ll be ready to monitor their activity in no time.</p>
                        </Col>
                        <Col md={4}>
                            <h3>Pick your plan</h3>
                            <p>Everyone’s needs are different, so we’ve got multiple plans that work for you, your family, and the devices you use.</p>
                        </Col>
                        <Col md={4}>
                            <h3>View the information</h3>
                            <p>Put your feet up. Once you’ve paid for your subscription, you can log in to your Control Panel and view activity on the device. Easy as that.</p>
                        </Col>
                    </Row>
                    <div className="but-2 mt-5">
                        <button className="btntry">Try Now</button>
                    </div>
                </Container>
            </section>


            <section className="section-9bg pt-5 pb-5">
                <Container className="pt-5 pb-5">
                    <div className="text-center">
                        <h2>About CallerSpot</h2>
                        <p>A few more words about CallerSpot</p>
                    </div>
                    <Row className="mt-5 pt-5">
                        <Col md={5} className="para-cont mt-2">
                            <p>CallerSpot is a leading Parental Control solution that allows parents to see all activities of
                                their kids on Mac, PC, iPhone, iPad, Android phone, or tablet.</p>
                            <p>Keep an eye on what your kids do online and protect them from cyberbullying, online predators,
                                adult content, suicidal thoughts, drug use and other dangers that might await them online. </p>
                            <div className="about__number">
                                <div className="about__number--txt">
                                    <span>10+</span>
                                    <p>Years of <br /> experience </p>
                                </div>
                                <div className="about__number--txt">
                                    <span>160+</span>
                                    <p> Countries <br /> supported  </p>
                                </div>
                                <div className="about__number--txt">
                                    <span>1,5</span>
                                    <p> Millions of <br /> happy  </p>
                                </div>
                            </div>

                        </Col>
                        <Col md={7}>
                            <img src={about} alt="" className="w-100" />
                        </Col>

                        <div className="text-center">
                            <div className="but-2 mt-5">
                                <button className="btntry">Try Now</button>
                            </div>
                        </div>

                    </Row>
                </Container>
            </section>

            <section className="section-10bg  pb-5 pt-5">
                <Container className="section-10 text-center pt-5 pb-5">
                    <h2>FAQ</h2>
                    <p>If any questions left</p>
                    <Row className="mt-5">

                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What if I need technical support?</Accordion.Header>
                                    <Accordion.Body>
                                        We offer a few levels of callerSpot™ technical assistance:
                                        Free Service - e-mail support, live chat assistance.
                                        Paid Phone Support – 24/7 personalized customer assistance with each step of the
                                        installation process in addition to the free support service.
                                        mAssistance - paid VIP rooting assistance, installation of callerSpot™ with Team Viewer,
                                        help with configuration and 24/7 technical assistance over the phone, in live chat and
                                        via email.
                                        The Paid Phone Support and mAssistance services are optional and non-refundable.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How to install CallerSpot on iOS?</Accordion.Header>
                                    <Accordion.Body>
                                        No installation is required for iOS. You need to provide iCloud
                                        account credentials of the target device. Once you input the iCloud credentials in your
                                        Control Panel, callerSpot™ will link the target iOS device with your personal
                                        callerSpot™ account and start the synchronization. Physical access to the iPhone may be
                                        required in case 2-step authentication is enabled.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How to install CallerSpot on Android?</Accordion.Header>
                                    <Accordion.Body>
                                        Place the order and log in to your Control Panel with login
                                        credentials provided in the welcome letter. Follow the instructions in your Panel to
                                        download and install the app. If you want to use Premium callerSpot™ features, you will
                                        need to root your device. Rooting is not complicated and our support team can guide you
                                        through the process.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. Where can I see the information from the device?</Accordion.Header>
                                    <Accordion.Body>
                                        Once CallerSpot is set up on the device, the information will
                                        automatically be displayed in your personal Control Panel that can be accessed from any
                                        browser.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How often is the information updated?</Accordion.Header>
                                    <Accordion.Body>
                                        If you’re using callerSpot™ on iOS, you will receive the
                                        information within 24 hours since the latest back up has been done.
                                        If you have callerSpot™ on Android, you can expect to receive updates based on the
                                        Update Interval you have selected in the Control Panel.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How often is the information updated?</Accordion.Header>
                                    <Accordion.Body>
                                        If you’re using callerSpot™ on iOS, you will receive the
                                        information within 24 hours since the latest back up has been done.
                                        If you have callerSpot™ on Android, you can expect to receive updates based on the
                                        Update Interval you have selected in the Control Panel.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How many devices does the subscription cover?</Accordion.Header>
                                    <Accordion.Body>
                                        One subscription plan covers one device only. If you want to
                                        connect multiple devices, consider getting our Family Kit that allows you to monitor up
                                        to 3 devices at the same time.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. Can I change devices without changing the subscription?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes. In case your child has lost their phone, or you've bought
                                        them a new one, you can switch to a different device within the same subscription
                                        without paying any additional fees.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={8} sm={12} className="offset-md-2">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. Will I be notified if the app is uninstalled?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes. You will receive a notification in case the application was
                                        removed from the device.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <div className="but-2 mt-5">
                            <button className="btntry">Try Now</button>
                        </div>

                    </Row>
                </Container>
            </section>


        </>
    );



};
export default CloudCenter;