import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/autodialer/DynamicQueue.svg';
import AgentSpecificCallingProgressiveDialers from '../../assets/autodialer/AgentSpecificCallingProgressiveDialers.svg';
import DetailedReport from '../../assets/autodialer/DetailedReport.svg';
import SMS from '../../assets/autodialer/SMS.svg';
import CallRecording from '../../assets/autodialer/CallRecording.svg';
import LocationTracking from '../../assets/autodialer/LocationTracking.svg';
import Vectorlogo from '../../assets/autodialer/IncreasedAgentEffectiveness.svg';
import Keepingdata from '../../assets/autodialer/Keepingdata.svg';
import Branding from '../../assets/autodialer/Branding.svg';
import AccelerateOutboundCalling from '../../assets/autodialer/AccelerateOutboundCalling.svg';
import ReducedAgentIdleTime from '../../assets/autodialer/ReducedAgentIdleTime.svg';
import Rectangle from '../../assets/autodialer/AutoDialer.svg';
import AutoDialersec from '../../assets/autodialer/AutoDialersec.svg';

const autodialer = () => {

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <>

            <Helmet>
                <title>Advanced Dialer Solution | Auto Dialer | CallerSpot</title>
                <meta
                    name="description"
                    content="CallerSpot's auto dialer software is an outbound dialer solution helping businesses increase agent productivity and eliminate manual number dialling. Reach more customers faster."
                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Auto Dialer</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Dials phone numbers from a list and connects them to either a live agent or a prerecorded
                    message.</p>
            </div>

            <section className="bgcolor">
                <Container>

                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">What is an auto dialer and how does it work?</h2>

                            <p className="passages1 mt-3">Most of us are not aware of the basic functionality of an auto dialer. In case you are one of
                                those who is keen to explore about the same then the subsequent section of the tutorial will
                                offer all the necessary information. An auto dialer is a type of software that will automatically
                                dial the telephone numbers that have been pulled out from the list which eventually or
                                automatically connects to a live agent or any of the messages that have been pre-recorded. It
                                eventually depends upon the call flow of your auto dial software of what message you need to
                                assign. The best of an auto dialer is the call can be assigned automatically to the best dialers
                                or an agent.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>
                    <Row className="mt-4 reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={AutoDialersec} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How can auto dialer boost the business?</h2>

                            <p className="passages1 mt-3">Now that we are well versed in the fact that auto dialer is an automated calling software, the
                                next thing that a dialer in a call center would like to know is how auto dialer can boost the
                                business.</p>

                            <h4 className="text-22">Types of Auto Dialer?</h4>
                            <p className="passages1">There are several types of auto dialers designed for specific purposes:</p>

                            <ul>
                                <li className="passages1 mt-3">Preview Dialer</li>
                                <li className="passages1 mt-3">Power/Progressive Dialer</li>
                                <li className="passages1 mt-3">Predictive Dialer</li>
                            </ul>

                        </Col>


                    </Row>


                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Auto Dialer’s Features</h2>
                            <p className="page">Here are a few important features which qualify a Auto Dialer’s uniqueness.</p>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Dynamic Queue</Card.Title>
                                        <Card.Text>
                                            Allows users to map a different queue for any campaign without having to update the
                                            queue within the IVR, thereby saving precious time.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={AgentSpecificCallingProgressiveDialers} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Agent Specific Calling in Progressive Dialers</Card.Title>
                                        <Card.Text>
                                            Leads are mapped to specific agents based on their interaction history, expertise, etc.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={DetailedReport} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Detailed Report</Card.Title>
                                        <Card.Text>
                                            Get an enhanced daily report which will allow you to keep track of the daily and
                                            weekly call reports.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={SMS} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>SMS</Card.Title>
                                        <Card.Text>
                                            Get an automatic SMS through auto dialer software, which means you can
                                            automatically deliver messages from one end to another.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={CallRecording} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Call Recording</Card.Title>
                                        <Card.Text>
                                            Call recording and monitoring is an essential auto dialer feature for quality
                                            assurance. Can easily track all of the call records through the auto dialer.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '260px'}}>
                                    <Card.Img variant="top" src={LocationTracking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Location Tracking</Card.Title>
                                        <Card.Text>
                                            Agents can also do all of the necessary tracking
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>



            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Advantages of Auto Dialer</h2>
                            <p>In case you are going ahead to purchase an auto dialer or automatic calling dialer, the
                                next thing you want to know about is the advantages of an auto dialer.</p>
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Increased Agent Effectiveness</h3>
                                    <p>When compared to a non-dialler setting, auto dialers can enhance agent conversation
                                        time per hour.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={AccelerateOutboundCalling} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Accelerate Outbound Calling</h3>
                                    <p>Auto dialers assist contact centers in increasing operational efficiency by
                                        automatically dialling phone numbers and forwarding only answered calls to live
                                        agents.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ReducedAgentIdleTime} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Reduced Agent Idle Time</h3>
                                    <p>Predictive algorithms are used by auto dialers to determine the best moment to begin
                                        an outgoing call and reduce agent idle time.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Keepingdata} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Keeping data up to date</h3>
                                    <p>By automatically updating contact lists and eliminating inactive numbers, auto
                                        dialers can help keep data current.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Branding} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Branding</h3>
                                    <p>By playing pre-recorded messages, auto dialers can assist in maintaining a
                                        professional and consistent business image.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className=" mt-5 pt-4">
                <Container>
                    <Row className="bg">
                        <Col md={12} sm={12} lg={12} xl={6} style={{'padding':'60px'}}>
                            <h2 className="white">Auto Dialer for Call Centers</h2>
                            <h3 className="mt-4 number">Lead Generation</h3>
                            <p className="par mt-2">Optimize lead generation in B2B and B2C scenarios with dialers, as phone calls
                                remain an effective channel for generating leads.</p>
                            <h3 className="mt-4 number">Lead Nurture</h3>
                            <p className="par mt-2">Improve conversion rates with faster response times for inbound leads using dialer
                                software to quickly qualify and nurture leads.</p>
                            <h3 className="mt-4 number">Feedback</h3>
                            <p className="par mt-2">On all of the calls made customer feedback is quite crucial for growth &amp; superior
                                customer experience.</p>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6} className="imggg" >
                            <img src={cuate} className="w-100" style={{ 'maxWidth': '80%' }} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h3 className="test">FAQs</h3>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Who Should Use an Auto-Dialer?</Accordion.Header>
                                    <Accordion.Body>
                                        Anyone who needs to contact large numbers of people in a short space of time. This may
                                        include sales and marketing, emergency notification, political campaigning, and polling.
                                        Typical uses of auto-dialers include politicians, local government, educational
                                        establishments, financial organizations and most commonly, sales organizations.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: How does an auto-dialer work?</Accordion.Header>
                                    <Accordion.Body>
                                        An auto-dialer works by automatically dialing phone numbers from a pre-programmed
                                        list. Once the call is connected, the auto-dialer can play a pre-recorded message or connect
                                        the call to a live agent.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Do you offer any technical support?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, at CallerSpot we offer technical support to our customers. Our technical support team
                                        is available to assist with any issues or questions related to our Auto Dialer System, including
                                        setup, configuration, and troubleshooting. Our support team can be reached via phone,
                                        email, or online chat, and we strive to provide prompt and helpful assistance to our
                                        customers.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: What is the difference between a preview dialer and a progressive dialer?</Accordion.Header>
                                    <Accordion.Body>
                                        A preview dialer allows agents to preview customer information before making the call,
                                        giving them more context and helping them personalize the conversation. A progressive
                                        dialer, on the other hand, dials a new number as soon as an agent becomes available,
                                        ensuring that agents are always on the phone and maximizing productivity.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q: Can an auto-dialer be used for SMS or text messaging?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, some auto-dialers offer the capability to send SMS or text messages in addition to
                                        making phone calls. This can be useful for sending appointment reminders or other
                                        important information to customers.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                    </Row>

                </Container>
            </section>

        </>
    );



};
export default autodialer;