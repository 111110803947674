import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import aboutus from '../../assets/about_us.png';
import testimonial from '../../assets/testimonial.jpg';
import telephonecall from '../../assets/telephone-call.png';
import blog from '../../assets/blog.png';
import support from '../../assets/support.png';
import customer from '../../assets/customer.png';
import email from '../../assets/email.png';
import callcenter from '../../assets/callcenter.png';
import team from '../../assets/team.jpg';
import teamsec from '../../assets/teamsec.jpg';
import teamthird from '../../assets/teamthird.jpg';
import blogimgsec from '../../assets/blogimgsec.jpg';
import blogimg from '../../assets/blogimg.jpg';

const Callto = ({ phone, children }) => {
    return <a className="tel" href={`tel:${phone}`}>{children}</a>
  };
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a className="tel" href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }

const About = () => {

    return (
        <>

            <Helmet>
                <title>About Us | Callspot</title>
                <meta
                    name="description"
                    content="Callspot is a leading cloud telephony service provider in India. We help businesses communicate better with their customers and employees with our innovative and affordable solutions. Learn more about our vision, mission, and values here."

                />
            </Helmet>

            <section className="pt-5">
                <Container>
                    <Row className="headtop">
                        <Col md={12} sm={12} lg={6}>
                            <div>
                                <img src={aboutus} style={{ 'width': '80%', 'borderRadius': '40px 40px 40px 40px'}} />
                            </div>
                        </Col>
                        <Col md={12} sm={12} lg={6}>
                            <h1 className="donec mt-3">World-Class Contact Center Services</h1>
                            <p className="ban-text mt-1 pt-1">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                            <ul className="ban-text pt-4">
                                <li style={{ 'listStyle': 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.7.7 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>&ensp;
                                    <a href="./">There are many variations of passages. </a>
                                </li>
                                <li className="mt-3" style={{ 'listStyle': 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.7.7 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>&ensp;
                                    <a href="./">
                                        The majority have suffered alteration.</a>
                                </li>
                                <li className="mt-3" style={{ 'listStyle': 'none' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.7.7 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" /></svg>&ensp;
                                    <a href="./">It is a long established fact that a reader.</a>
                                </li>
                            </ul>

                            <Row>
                                <Col xl={3} lg={3} md={6} sm={12} className="mt-4">
                                    <Button className="btn btn-success">READ MORE</Button>
                                </Col>
                                <Col xl={3} lg={3} md={12} sm={12} className="mt-4">
                                    <img src={testimonial} style={{ 'width': '50px', 'borderRadius': '50%' }} />
                                    <img src={telephonecall} style={{ 'width': '50px', 'borderRadius': '50%', 'marginLeft': '-9px' }} />
                                </Col>
                                <Col xl={6} lg={3} md={12} sm={12} className="mt-4">
                                    <h4 style={{ 'fontWeight': '900', 'fontFamily': 'Nunito Sans', 'color': '#002C3E' }}> Contact Us: <Callto phone="+919711665756"> +919711665756 </Callto> </h4>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="" style={{ 'background': '#f7f8f3', 'marginTop': '130px' }}>
                <Container >

                    <Row >
                        <Col className="mb-5" xl={3} lg={3} md={6} sm={12}>
                            <Card className="cardd" style={{ 'minHeight': '180px', 'marginTop': '-50px' }}>
                                <Card.Body>
                                    <Card.Title style={{ 'textAlign': 'initial', 'fontWeight': '900', 'fontFamily': 'Nunito Sans' }}>Language Combinations</Card.Title>
                                    <div style={{ 'textAlign': 'justify' }}>
                                        <img src={support} style={{ 'width': '62px' }} />
                                    </div>
                                </Card.Body>
                                <div style={{ 'position': 'absolute', 'bottom': ' -15px', 'marginLeft': '35px' }}>
                                    <a href="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                    </a>
                                </div>
                            </Card>
                            <p style={{ 'fontSize': '18px', 'marginTop': '27px', 'marginBottom': '15px', 'fontFamily': 'Nunito Sans', 'color': '#023246', 'marginTop': '20px' }}> It is a long established fact that a reader will be distracted.</p>
                        </Col>
                        <Col className="mb-5" xl={3} lg={3} md={6} sm={12}>
                            <Card className="cardd" style={{ 'minHeight': '180px', 'marginTop': '-50px' }}>
                                <Card.Body>
                                    <Card.Title style={{ 'textAlign': 'initial', 'fontWeight': '900', 'fontFamily': 'Nunito Sans' }}>Outsourced Data Analytics</Card.Title>
                                    <div style={{ 'textAlign': 'justify' }}>
                                        <img src={customer} style={{ 'width': '62px' }} />
                                    </div>
                                </Card.Body>
                                <div style={{ 'position': 'absolute', 'bottom': ' -15px', 'marginLeft': '35px' }}>
                                    <a href="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                    </a>
                                </div>
                            </Card>
                            <p style={{ 'fontSize': '18px', 'marginTop': '27px', 'marginBottom': '15px', 'fontFamily': 'Nunito Sans', 'color': '#023246', 'marginTop': '20px' }}> It is a long established fact that a reader will be distracted.</p>
                        </Col>
                        <Col className="mb-5" xl={3} lg={3} md={6} sm={12}>
                            <Card className="cardd" style={{ 'minHeight': '180px', 'marginTop': '-50px' }}>
                                <Card.Body>
                                    <Card.Title style={{ 'textAlign': 'initial', 'fontWeight': '900', 'fontFamily': 'Nunito Sans' }}>Email Support Outsourcing</Card.Title>
                                    <div style={{ 'textAlign': 'justify' }}>
                                        <img src={email} style={{ 'width': '62px' }} />
                                    </div>
                                </Card.Body>
                                <div style={{ 'position': 'absolute', 'bottom': ' -15px', 'marginLeft': '35px' }}>
                                    <a href="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                    </a>
                                </div>
                            </Card>
                            <p style={{ 'fontSize': '18px', 'marginTop': '27px', 'marginBottom': '15px', 'fontFamily': 'Nunito Sans', 'color': '#023246', 'marginTop': '20px' }}> It is a long established fact that a reader will be distracted.</p>
                        </Col>
                        <Col className="mb-5" xl={3} lg={3} md={6} sm={12}>
                            <Card className="cardd" style={{ 'minHeight': '180px', 'marginTop': '-50px' }}>
                                <Card.Body>
                                    <Card.Title style={{ 'textAlign': 'initial', 'fontWeight': '900', 'fontFamily': 'Nunito Sans' }}>Contact Center Outsourcing</Card.Title>
                                    <div style={{ 'textAlign': 'justify' }}>
                                        <img src={callcenter} style={{ 'width': '62px' }} />
                                    </div>
                                </Card.Body>
                                <div style={{ 'position': 'absolute', 'bottom': ' -15px', 'marginLeft': '35px' }}>
                                    <a href="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                    </a>
                                </div>
                            </Card>
                            <p style={{ 'fontSize': '18px', ' marginTop': '27px', 'marginBottom': '15px', 'fontFamily': 'Nunito Sans', 'color': '#023246', 'marginTop': '20px' }}> It is a long established fact that a reader will be distracted.</p>
                        </Col>


                    </Row>

                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col style={{ 'textAlign': 'center' }}>
                            <h2 style={{ 'fontWeight': '900', 'fontFamily': 'Nunito Sans', 'color': '#002C3E' }}>Our Blog</h2>
                            {/* <p style={{ 'color': '#002C3E' }}>News and Insights</p> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card style={{ 'padding': '10px' }}>
                                <Card.Img src={teamthird} style={{ 'borderRadius': '20px 20px 20px 20px', 'height': '313px' }} />
                                <div className="mt-3 pb-3">
                                    <a href="">
                                        <h5 style={{ 'color': 'rgb(0, 44, 62', 'fontSize': '24px' }}>How to choose the Best BPO Company</h5>
                                    </a>
                                    <br />
                                    <a href="" style={{ 'fontWeight': '700', 'fontSize': '17px' }}>
                                        Read More
                                    </a>
                                </div>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card style={{ 'padding': '10px' }}>
                                <Card.Img src={blogimg} style={{ 'borderRadius': '20px 20px 20px 20px', 'height': '313px' }} />

                                <div className="mt-3 pb-3">
                                    <a href="">
                                        <h5 style={{ 'color': 'rgb(0, 44, 62', 'fontSize': '24px' }}>How to choose the Best BPO Company</h5>
                                    </a>
                                    <br />
                                    <a href="" style={{ 'fontWeight': '700', 'fontSize': '17px' }}>
                                        Read More
                                    </a>
                                </div>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card style={{ 'padding': '10px' }}>
                                <Card.Img src={blogimgsec} style={{ 'borderRadius': '20px 20px 20px 20px', 'height': '313px' }} />
                                <div className="mt-3 pb-3">
                                    <a href="">
                                        <h5 style={{ 'color': 'rgb(0, 44, 62', 'fontSize': '24px' }}>How to choose the Best BPO Company</h5>
                                    </a>
                                    <br />
                                    <a href="" style={{ 'fontWeight': '700', 'fontSize': '17px' }}>
                                        Read More
                                    </a>
                                </div>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </section>


        </>
    );


};
export default About;