import React from "react";
import { Menu, MenuItem, SubMenu, ProSidebarProvider } from "react-pro-sidebar";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { FaParking } from "react-icons/fa";
import { FaListUl } from "react-icons/fa";
import { FaChevronCircleRight } from "react-icons/fa";
const Aside = () => {
  return (
    <ProSidebarProvider>
      <Menu title="Main">
        <SubMenu title={"Services"} label="Product" icon={<FaParking />}>

          <SubMenu label="Voice">
            <MenuItem> <a href={"/call-tracking"}>Call Tracking</a></MenuItem>
            {/* <MenuItem> <Link to={"/call-tracking"}>Call Tracking</Link></MenuItem> */}
            <MenuItem> <a href={"/cloud-center"}>Cloud Contact Center</a></MenuItem>
            <MenuItem><a href={"/virtual-number"}> Virtual Number</a></MenuItem>
            <MenuItem><a href={"/ivr-Service"}> IVR Service</a></MenuItem>
            <MenuItem><a href={"/number-masking"}> Number Masking</a></MenuItem>
            <MenuItem> <a href={"/lead-management"}>Lead Management</a></MenuItem>
            <MenuItem><a href={"/missed-call"}> Missed Call</a></MenuItem>
            <MenuItem><a href={"/toll-free-number"}> Toll Free Number</a></MenuItem>
            <MenuItem><a href={"/automated-outbond-calling"}> Automated Outbond Calling</a></MenuItem>
            <MenuItem> <a href={"/click-to-call"}>Click to Call</a></MenuItem>
            {/* <MenuItem><Link to={"/SoftPhone-with-WebRTC-with-mpls"}> SoftPhone With WebRTC with MPLS</Link></MenuItem> */}
            {/* <MenuItem><Link to={"/true-Coller"}> True Caller</Link></MenuItem> */}
            <MenuItem> <a href={"/auto-dialer"}>Auto Dialer</a></MenuItem>

          </SubMenu>




          <SubMenu label="SMS">
            <MenuItem> <a href={"/promotional-sms"}>Promotional SMS</a></MenuItem>
            <MenuItem><a href={"/transactional-sms"}> Transactional SMS</a></MenuItem>
            <MenuItem><a href={"/whatsapp-business-api"}>WhatsApp Business API</a></MenuItem>
          </SubMenu>


          <SubMenu label="Automation">
            <MenuItem> <a href={"/text-to-speech"}>Text-To-Speech</a></MenuItem>
            <MenuItem><a href={"/all-chatBot"}> AI ChatBot/VoiceBot</a></MenuItem>
            <MenuItem><a href={"/speech-analytics"}> Speech Analytics</a></MenuItem>
          </SubMenu>

        </SubMenu>




        <SubMenu title={"Services"} label="Solution" icon={<FaListUl />}>

          <SubMenu label="Indutry">
            <MenuItem> <a href={"/banking"}>Banking & Finacial</a></MenuItem>
            <MenuItem> <a href={"/education"}>Education</a></MenuItem>
            <MenuItem><a href={"/healthcare"}> Healthcare</a></MenuItem>
            <MenuItem><a href={"/retail-ecommerce"}> Retail Ecommerce</a></MenuItem>
            {/* <MenuItem> <Link to={"/finance"}>Finance</Link></MenuItem> */}
            <MenuItem><a href={"/transportation-logistics"}> Transportation Logistics</a></MenuItem>
            <MenuItem><a href={"/manufacturing"}> Manufacturing</a></MenuItem>
            {/* <MenuItem><Link to={"/public-sector"}> Public Sector</Link></MenuItem> */}

          </SubMenu>

        </SubMenu>



        <MenuItem icon={<FaChevronCircleRight />}>
          <a href={"/contact-us"}  >Contact Us</a>
        </MenuItem>
        <MenuItem icon={<FaChevronCircleRight />}>
          <a href={"/about-us"}>About Us</a>
        </MenuItem>


        <MenuItem icon={<FaChevronCircleRight />}>
          <a href={"/pricing"}>Pricing</a>
        </MenuItem>



        {/* <SubMenu title={"Solution"} label="Company" icon={<FaRegBuilding />}>
          <MenuItem>
          <Link to={"/about-us"}>About Us</Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/contact-us"}>Contact Us</Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/career"}>Career</Link>
          </MenuItem>

        </SubMenu> */}

        {/* <SubMenu
          suffix={<span className="badge yellow"></span>}
          title={"Product"} label="Resources" icon={<FaSnowflake />}
        // icon={<FaRegSun />}
        >
          <MenuItem>
            <Link to={"/webinar"}>Webinars</Link>
          </MenuItem>
          <MenuItem>
            <Link to={"/blog"}>Blog</Link>
          </MenuItem>


        </SubMenu> */}
        {/* <Button variant="secondary" className="getin">Get in Touch</Button>{' '} */}
      </Menu>

    </ProSidebarProvider>

  );
};

export default Aside;
