import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home/index";
import Voice from "../pages/voice";
import Banking from "../pages/Banking";
import IT from "../pages/IT/it";
import Vertual from "../pages/Virtual";
import CloudCenter from "../pages/CloudCenter";
import CallTracking from "../pages/CallTracking";
import Education from "../pages/Education";
import Healthcare from "../pages/Healthcare";
import Automobile from "../pages/Automobile";
import Retailecommerce from "../pages/Retailecommerce";
import TransportationLogistics from "../pages/TransportationLogistics";
import TravelTurismHospitalties from "../pages/TravelTurismHospitalties";
import Logsticks from "../pages/Logsticks";
import Salesleadgeneration from "../pages/Salesleadgeneration";
import Trackmarketing from "../pages/Trackmarketing";
import Remotework from "../pages/Remotework";
import Customerservicesupport from "../pages/Customerservicesupport";
import Logsticsdelivery from "../pages/Logsticsdelivery";
import Automatedcollservice from "../pages/Automatedcollservice";
import securitycompliance from "../pages/securitycompliance";
import About from "../pages/About";
import Contact from "../pages/contact";
import Pricing from "../pages/Pricing";
import Finance from "../pages/Finance";
import Manufacturing from "../pages/Manufacturing";
import Publicsector from "../pages/Publicsector";
import promotionalsms from "../pages/promotionalsms";
import SpeechAnalytics from "../pages/SpeechAnalytics";
import TrialDemo from "../pages/TrialDemo";
import TryForFree from "../pages/TryForFree";
import TTS from "../pages/TTS";
import Allchatbot from "../pages/Allchatbot";
import Transactionalsms from "../pages/Transactionalsms";
import WhatsApi from "../pages/WhatsappAPI";
import Ivrservice from "../pages/Ivrservice";
import Numbermasking from "../pages/Numbermasking";
import LeadManagement from "../pages/LeadManagement";
import Missedcall from "../pages/Missedcall";
import TollFreeNumber from "../pages/TollFreeNumber";
import AutomatedOutbondCalling from "../pages/AutomatedOutbondCalling";
import ClicktoCall from "../pages/ClicktoCall";
import SoftPhonewithWebRTCwithMPLS from "../pages/SoftPhonewithWebRTCwithMPLS";
import TrueColler from "../pages/TrueColler";
import autodialer from "../pages/Autodialer";
import Careers from "../pages/Careers";
import Webinars from "../pages/Webinars";
import Blog from "../pages/Blog";
import TermsCondition from "../pages/TermsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";






const Routing = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/voice" component={Voice} />
            <Route path="/banking" component={Banking} />
            <Route path="/its" component={IT} />
            <Route path="/virtual-number" component={Vertual} />
            <Route path="/cloud-center" component={CloudCenter} />
            <Route path="/call-tracking" component={CallTracking} />
            <Route path="/education" component={Education} />
            <Route path="/healthcare" component={Healthcare} />
            <Route path="/automobile" component={Automobile} />
            <Route path="/retail-ecommerce" component={Retailecommerce} />
            <Route path="/transportation-logistics" component={TransportationLogistics} />
            <Route path="/travel-turism-hospitalties" component={TravelTurismHospitalties} />
            <Route path="/logsticks" component={Logsticks} />
            <Route path="/sales-lead-generation" component={Salesleadgeneration} />
            <Route path="/track-marketing" component={Trackmarketing} />
            <Route path="/remotework-work-solution" component={Remotework} />
            <Route path="/customer-service-support" component={Customerservicesupport} />
            <Route path="/logstics-delivery" component={Logsticsdelivery} />
            <Route path="/automated-coll-service" component={Automatedcollservice} />
            <Route path="/security-comoliance" component={securitycompliance} />
            <Route path="/about-us" component={About} />
            <Route path="/contact-us" component={Contact} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/finance" component={Finance} />
            <Route path="/manufacturing" component={Manufacturing} />
            <Route path="/public-sector" component={Publicsector} />
            <Route path="/promotional-sms" component={promotionalsms} />
            <Route path="/text-to-speech" component={TTS} />
            <Route path="/all-chatbot" component={Allchatbot} />
            <Route path="/speech-analytics" component={SpeechAnalytics} />

            <Route path="/transactional-sms" component={Transactionalsms} />
            <Route path="/whatsapp-business-api" component={WhatsApi} />
            <Route path="/ivr-Service" component={Ivrservice} />
            <Route path="/number-masking" component={Numbermasking} />
            <Route path="/lead-management" component={LeadManagement} />
            <Route path="/missed-call" component={Missedcall} />
            <Route path="/toll-free-number" component={TollFreeNumber} />
            <Route path="/automated-outbond-calling" component={AutomatedOutbondCalling} />
            <Route path="/click-to-call" component={ClicktoCall} />
            <Route path="/softphone-with-webrtc-with-mpls" component={SoftPhonewithWebRTCwithMPLS} />
            <Route path="/true-Caller" component={TrueColler} />
            <Route path="/auto-dialer" component={autodialer} />
            <Route path="/career" component={Careers} />
            <Route path="/webinar" component={Webinars} />
            <Route path="/blog" component={Blog} />
            <Route path="/term-and-condition" component={TermsCondition} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/trial-demo" component={TrialDemo} />
            <Route path="/try-for-free" component={TryForFree} />

        </Switch>

    );
};
export default Routing;