import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/tsms/TransactionalSMS.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/tsms/RealtimeDeliveryReport.svg';
import SMSfacility from '../../assets/tsms/SMSfacility.svg';
import PersonalizedTemplates from '../../assets/tsms/PersonalizedTemplates.svg';
import APIintegration from '../../assets/tsms/APIintegration.svg';
import SendUnlimitedText from '../../assets/tsms/SendUnlimitedText.svg';
import TrackingSMSfacility from '../../assets/tsms/TrackingSMSfacility.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/tsms/Savesmuchmoretime.svg';
import Manageentireoperationeffectively from '../../assets/tsms/Manageentireoperationeffectively.svg';
import AccurateResults from '../../assets/tsms/AccurateResults.svg';
import Multilanguage from '../../assets/tsms/Multilanguage.svg';
import AccurateReliable from '../../assets/tsms/AccurateReliable.svg';
import ConvenienceAssuredDelivery from '../../assets/tsms/ConvenienceAssuredDelivery.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    

    return (
        <>

<Helmet>
        <title>
        Transactional SMS | Business SMS Service | SMS Marketing | Callerspot
        </title>
        <meta
            name="description"
            content="Transactional SMS Service: Send fast and reliable SMS to your customers with CallerSpot, the best transactional SMS service provider in India. Get started today with a free trial!"
        />

    </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white">Transactional SMS Solution</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>AI-powered conversational Bots that take customer experience to another level with ChatBot/VoiceBot Solutions</p>
            </div>

            <section className="bgcolor">

                <section className="">
                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">What Is A Transactional SMS Solution?</h2>

                                <p className="passages1 mt-3">Transactional SMS is just an automated SMS service that is sent by any organization or business entity to its customers. It improves the interactions with the customer and provides real-time notifications or alerts for orders or bookings</p>
                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>


                    </Container>
                </section>

                <section className="mt-5">
                    <Row>
                        <div className="text-center">
                            <h2 className="featured">Features of the Transactional SMS</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>
                </section>

                <section className="">
                    <Container>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Real-time Delivery Report</Card.Title>
                                        <Card.Text>
                                            Get the message sent report after the delivery of the message.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={SMSfacility} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title> 24/7 SMS facility</Card.Title>
                                        <Card.Text>
                                            Send text messages anytime from anywhere.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={PersonalizedTemplates} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Personalized Templates</Card.Title>
                                        <Card.Text>
                                            Send bulk messages as per the requirement and customized templates.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>

                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={APIintegration} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Get the API integration</Card.Title>
                                        <Card.Text>
                                            Integrate with any software & auto-schedule SMS.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={SendUnlimitedText} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Send Unlimited Text</Card.Title>
                                        <Card.Text>
                                            Send a large quality or volume message for any location. Redact particular material such as improper language, private and personal information from your transcription.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                               
                                <Card className="red" style={{'minHeight': '252px'}}>
                                    <Card.Img variant="top" src={TrackingSMSfacility} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Tracking SMS facility</Card.Title>
                                        <Card.Text>
                                            Track & monitor all the delivered messages.
                                        </Card.Text>
                                    </Card.Body>
                                    
                                    
                                </Card>
                                
                            </Col>
                        </Row>

                    </Container>
                </section>
            </section>
   


            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Transactional SMS</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Saves much more time</h3>
                                    <p> It generates auto-trigger messages.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={AccurateReliable} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Accurate & Reliable</h3>
                                    <p>Send bulk SMS for any location and guaranteed delivery.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ConvenienceAssuredDelivery} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Convenience & Assured Delivery</h3>
                                    <p>Send an SMS with limited characters & get the information about the read.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={AccurateResults} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Accurate Results</h3>
                                    <p>Relevant & specific results on year, currencies, addresses, etc</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Multilanguage} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Multi-language</h3>
                                    <p>Get the availability of more than 125 languages.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Manageentireoperationeffectively} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Manage the entire operation effectively</h3>
                                    <p>Get everything from beginning to end on just one platform.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>



            {/* <section className="mt-5">
                <Container>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                </Container>
            </section> */}

        </>
    );


};
export default Virtual;