import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import TrackMarketingROIEffectively from '../../assets/health/TrackMarketingROIEffectively.svg';
import EasilyGeneraLeads from '../../assets/health/EasilyGenerateLeads.svg';
import RemoteWorking from '../../assets/health/RemoteWorking.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/Vectorlogo.png';
import OwlCarousel from 'react-owl-carousel';
import Rectangle from '../../assets/health/HealthcareIndustry.svg';
import cloudcommunication from '../../assets/health/cloudcommunication.svg';
import Securitconformity from '../../assets/health/CollectFeedback.svg';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Healthcare = () => {


    return (
        <>

            <Helmet>
                <title>Healthcare Industry - Cloud Telephony Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="Streamline healthcare communication with CallerSpot's cloud telephony services. Deliver quality healthcare services with our IVR, Bulk SMS and other features."

                />
            </Helmet>



            <div className="service-bannar1">
                <h1 className="education text-center text-white">Healthcare Industry</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Optimize Your Call Routing and Improve Efficiency with our Interactive Voice Response (IVR) Service.</p> */}
            </div>

            <section className="bgcolor">


                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Is Callerspot Helping The Healthcare Industry?</h2>

                            <p className="passages1 mt-3">You can now deliver engagement services via phone, video, or other cloud communication platforms to serve your patients in a better way. Secure communications will help you in creating a dependable healthcare organization. There was a time when cloud communications were beyond our imaginations, but now it is going to link every stage of healthcare operation. Check out how cloud communications are helping the healthcare industry.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>
                    <Row className="mt-4 reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={cloudcommunication} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2"> Check out the benefits of cloud
                                communication.</h2>

                            <ul>
                                <li className="passages1 mt-3">Promotes engagement in digital-first patient</li>
                                <li className="passages1">Broader self-service options for patients</li>
                                <li className="passages1">Reduces the number of people in the clinical workforce</li>
                                <li className="passages1">Gives agile mobile-first collaboration experiences </li>
                                <li className="passages1">Helps patients review physician ratings and hospitals improve patient outcomes</li>
                            </ul>
                            <p className="passages1">Callerspot develops a cloud telephony network for healthcare businesses of all horizons. We have been providing future-oriented communication solutions as per exact user needs. Our company empowers smart work Cloud Communication Technologies and Video Platforms to be available to your patients from any corner of the world.</p>

                        </Col>


                    </Row>


                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Fulfilling Your Healthcare Industry
                                Needs for Excellence</h2>
                            <p className="page">Address all of your patient’s concerns with smart engagement tools to provide
                                immediate assistance.</p>
                        </div>
                    </Row>

                    <Row className="mt-4">
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '330px'}}>
                                    <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Lead Generation and Sales</Card.Title>
                                        <Card.Text>
                                            Streamline and enhance your sales and lead generation process using our Cloud
                                            Contact Center, Lead Management, and Missed Call Solution. Boost sales with a
                                            targeted approach tailored precisely to meet your unique requirements.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '330px'}}>
                                    <Card.Img variant="top" src={TrackMarketingROIEffectively} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Track Marketing ROI</Card.Title>
                                        <Card.Text>
                                            Optimize your marketing campaigns with our unified multi-channel platform. Monitor and
                                            analyze results by integrating Missed Call Solution and Virtual Business Numbers into
                                            your online and offline marketing efforts.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '330px'}}>
                                    <Card.Img variant="top" src={RemoteWorking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Remote Working</Card.Title>
                                        <Card.Text>
                                            Enhance productivity and flexibility with our smart remote work Cloud Communication
                                            Technology and Video Platform. Stay connected with your customers from anywhere, at
                                            any time.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-3">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '330px'}}>
                                    <Card.Img variant="top" src={Securitconformity} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Customer Support &amp; Valuable Feedback</Card.Title>
                                        <Card.Text>
                                            Earn your customer’s trust by delivering an exceptional customer experience. Gather
                                            valuable feedback through Video Platform, Speech Analytics, Chatbots/Voicebots,
                                            Automated Calls, and SMS, ensuring a customer-centric solution for your business.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );

};
export default Healthcare;