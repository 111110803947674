import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/pageimages/AutomaticCallDistribution(ACD).svg';
import InteractiveVoiceResponse from '../../assets/pageimages/InteractiveVoiceResponse(IVR)system.svg';
import Callrecordingandmonitoring from '../../assets/pageimages/Callrecordingandmonitoring.svg';
import timeanalyticsandreporting from '../../assets/pageimages/Real-timeanalyticsandreporting.svg';
import CRMintegration from '../../assets/pageimages/CRMintegration.svg';
import channelsupport from '../../assets/pageimages/Multi-channelsupport.svg';
import basedrouting from '../../assets/pageimages/Skills-basedrouting.svg';
import Callqueuingandcallbackoptions from '../../assets/pageimages/Callqueuingandcallbackoptions.svg';
import Agentperformancetracking from '../../assets/pageimages/Agentperformancetracking.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Enhancedcustomerexperience from '../../assets/pageimages/Enhancedcustomerexperience.svg';
import Increasedagentproductivity from '../../assets/pageimages/Increasedagentproductivity.svg';
import Scalabilityandflexibility from '../../assets/pageimages/Scalabilityandflexibility.svg';
import Costsavings from '../../assets/pageimages/Costsavings.svg';
import Improvedanalyticsandreporting from '../../assets/pageimages/Improvedanalyticsandreporting.svg';
import AgentPerformanceInsights from '../../assets/pageimages/AgentPerformanceInsights.svg';
import Rectangle from '../../assets/pageimages/Image.svg';

const CloudCenter = () => {

    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <>

            <Helmet>
                <title>Call Center Software | Cloud Call Center Solution | CallerSpot</title>
                <meta
                    name="description"
                    content="Cloud call center software helps to handle the large volume of calls. Callerspot provides cloud contact center solutions to business to improve customer experience."

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Cloud Call Center</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Maximize Efficiency and Productivity with our
                    Cloud Call Center Solution</p>
            </div>
            <section className="bgcolor">
                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <h2 className="text-2">What is a Cloud Call Center Solution?</h2>
                            <p className="passages1 mt-4">A Cloud Call Center Solution is a software-based communication system that enables
                                businesses to efficiently handle inbound and outbound customer interactions. It leverages
                                cloud technology to deliver call center functionality, eliminating the need for on-premises
                                hardware and infrastructure.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5">
                            <img src={Rectangle} style={{ 'width': '100%', 'maxHeight': '85%' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h2 className="featured">Features of Cloud Call Center Software</h2>
                            {/* <p className="page">Here are a few important features which qualify a Auto Dialer’s uniqueness.</p> */}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="mt-3" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Automatic Call Distribution (ACD)</Card.Title>
                                    <Card.Text>
                                        Efficiently route incoming calls to the right agents
                                        for prompt assistance and improved customer satisfaction.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className=" mt-3" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={InteractiveVoiceResponse} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Interactive Voice Response (IVR) system</Card.Title>
                                    <Card.Text>
                                        Empower customers to resolve queries
                                        independently through intuitive self-service menus.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-3" xl={4} lg={4} md={6} sm={12}>
                            {/* <div md={12} sm={2} lg={4}> */}
                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={Callrecordingandmonitoring} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Call recording and monitoring</Card.Title>
                                    <Card.Text>
                                        Capture and analyze customer interactions for quality
                                        assurance and training purposes.
                                    </Card.Text>
                                </Card.Body>
                                {/*  */}
                            </Card>
                            {/* </div> */}
                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={timeanalyticsandreporting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Real-time analytics and reporting</Card.Title>
                                    <Card.Text>
                                        Gain valuable insights into call center performance
                                        to optimize operations and enhance customer experiences.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={CRMintegration} className="mt-4" />
                                <Card.Body>
                                    <Card.Title>CRM integration</Card.Title>
                                    <Card.Text>
                                        Seamlessly access customer information during calls, enabling
                                        personalized interactions and efficient issue resolution.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={channelsupport} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Multi-channel support</Card.Title>
                                    <Card.Text>
                                        Engage with customers through their preferred channels, such
                                        as voice, email, chat, social media, and SMS.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={basedrouting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Skills-based routing</Card.Title>
                                    <Card.Text>
                                        Match customer inquiries with agents possessing the necessary
                                        expertise for efficient problem-solving.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={Callqueuingandcallbackoptions} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Call queuing and callback options</Card.Title>
                                    <Card.Text>
                                        Reduce customer wait times with intelligent
                                        queuing and offer the convenience of callback services.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                        <Col className="mt-4" xl={4} lg={4} md={6} sm={12}>

                            <Card className="red" style={{'minHeight': '254px'}}>
                                <Card.Img variant="top" src={Agentperformancetracking} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Agent performance tracking</Card.Title>
                                    <Card.Text>
                                        Monitor agent metrics, provide
                                        feedback, and improve their skills for enhanced productivity.
                                    </Card.Text>
                                </Card.Body>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="mt-4">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Cloud Call Center</h2>
                        </div>
                    </Row>
                </Container>
            </section>
            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2" >
                                    <div className="">
                                        <img src={Enhancedcustomerexperience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Enhanced customer experience</h3>
                                    <p>By offering multi-channel support and personalized
                                        interactions, cloud call centers help businesses deliver superior customer service.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div className="">
                                        <img src={Increasedagentproductivity} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Increased agent productivity</h3>
                                    <p>Advanced features like ACD, IVR, and CRM integration
                                        enable agents to handle calls efficiently, access relevant information quickly, and
                                        focus on providing effective solutions.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div className="">
                                        <img src={Scalabilityandflexibility} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Scalability and flexibility</h3>
                                    <p>Cloud-based solutions allow businesses to scale their call
                                        center operations up or down based on changing demands, ensuring optimal
                                        resource allocation.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div className="">
                                        <img src={Costsavings} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Cost savings</h3>
                                    <p>Cloud call center solutions eliminate the need for significant upfront
                                        investments in hardware and maintenance, reducing operational costs.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div className="">
                                        <img src={Improvedanalyticsandreporting} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Improved analytics and reporting</h3>
                                    <p>Cloud contact centers provide comprehensive data
                                        and analytics on call metrics, agent performance, and customer behavior, enabling
                                        businesses to make data-driven decisions and optimize processes.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row className="bene">
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div className="">
                                        <img src={AgentPerformanceInsights} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Agent’s Performance Insights</h3>
                                    <p>Track and record interactions between the agent and
                                        the client to acquire deeper insights and analyze the agent&#39;s performance.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="test">FAQs</h2>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What features are provided in the cloud call center or cloud contact center solution?</Accordion.Header>
                                    <Accordion.Body>
                                        CallerSpot offers a comprehensive cloud call center or contact center solution with various
                                        features. These include call routing, call recording, call analytics, interactive voice response
                                        (IVR), real-time monitoring, and agent performance tracking.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion className="mt-4">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How can I use call center software solutions for better call routing?</Accordion.Header>
                                    <Accordion.Body>
                                        To enhance call routing, CallerSpot&#39;s call center software allows you to set up intelligent
                                        routing rules based on various factors such as the caller&#39;s location, language preference,
                                        and previous interactions. This ensures that calls are directed to the most appropriate agent
                                        or department, resulting in improved customer service.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the use cases of contact center solutions?</Accordion.Header>
                                    <Accordion.Body>
                                        Contact center solutions provided by CallerSpot cater to various use cases, such as
                                        customer support, sales, appointment scheduling, and helpdesk services. The software can
                                        be customized to meet specific business requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What does the term IVR mean? How does it work?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR stands for Interactive Voice Response. It is an automated system that interacts with
                                        callers through voice prompts and touch-tone inputs. IVR works by presenting a menu of
                                        options to callers and allowing them to make selections using their telephone keypad. Based
                                        on the chosen option, the IVR system routes the call to the appropriate department or
                                        provides pre-recorded information.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How can I set up a small contact center to manage 200 calls daily?</Accordion.Header>
                                    <Accordion.Body>
                                        To set up a small contact center handling 200 calls daily, you can start by obtaining
                                        CallerSpot&#39;s cloud call center software. It offers easy scalability and can be accessed
                                        remotely by your agents. Configure the routing rules, integrate with your existing telephony
                                        infrastructure, and train your agents to handle calls efficiently.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the features of cloud call center software?</Accordion.Header>
                                    <Accordion.Body>
                                        CallerSpot&#39;s cloud call center software offers various features, including automatic call
                                        distribution, call recording, real-time analytics, CRM integration, and agent performance
                                        monitoring. It provides a centralized platform for managing incoming and outgoing calls,
                                        ensuring efficient call handling and improved customer experience.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is an inbound call center?</Accordion.Header>
                                    <Accordion.Body>
                                        An inbound call center primarily handles incoming calls from customers or prospects seeking
                                        assistance, support, or information. Agents in an inbound call center are trained to handle a
                                        wide range of customer inquiries and provide solutions.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is an outbound call center?</Accordion.Header>
                                    <Accordion.Body>
                                        An outbound call center focuses on making outgoing calls to customers or prospects for
                                        sales, marketing, surveys, or collections purposes. Agents in an outbound call center are
                                        skilled in conducting sales pitches, lead generation, and gathering customer feedback.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are inbound and outbound call center services and features?</Accordion.Header>
                                    <Accordion.Body>
                                        Inbound call center services involve handling incoming customer calls, providing support,
                                        resolving issues, taking orders, and answering inquiries. Features may include call queuing,
                                        skill-based routing, call recording, and CRM integration. Outbound call center services
                                        involve making outgoing calls for sales, lead generation, market research, or survey
                                        purposes. Features may include auto-dialing, call scripting, campaign management, and
                                        performance tracking.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the differences between IVR and OBD?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR (Interactive Voice Response) is an automated system that interacts with callers through
                                        voice prompts and touch-tone inputs, allowing them to navigate through a menu of options.
                                        OBD (Outbound Dialing) refers to the process of making outgoing calls to customers or
                                        prospects for various purposes, such as sales, marketing, or surveys. The main difference is
                                        that IVR is focused on inbound calls and navigation options, while OBD is about initiating
                                        outbound calls.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is cloud telephony?</Accordion.Header>
                                    <Accordion.Body>
                                        Cloud telephony is a communication technology that allows voice and data services to be
                                        supplied via the internet rather than traditional phone lines. It helps enterprises to set up and
                                        operate their phone systems in the cloud, providing flexibility, scalability, and cost savings.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is hosted IVR?</Accordion.Header>
                                    <Accordion.Body>
                                        Hosted IVR is an IVR system that is hosted and operated in the cloud by a service provider.
                                        Instead of investing in on-premises gear and software, organizations may benefit from the
                                        capability and scalability of IVR through the use of a hosted IVR solution.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                    </Row>

                </Container>
            </section>

        </>
    );



};
export default CloudCenter;