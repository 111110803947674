import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Carousel } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';
import "../Home/style.css";
import { Container, Row, Col, Tab, Nav, Card } from "react-bootstrap";
import Image2 from '../../assets/banner-1.png';
import Image4 from '../../assets/logo-1.png';
import Image5 from '../../assets/logo-2.png';
import Image250 from '../../assets/Group899.png';
import Rirabhlogo from '../../assets/Rirabh-new.160b2f51.svg';
import logo from '../../assets/digilab-logo.png';
import rirabhinter from '../../assets/rirabhinter.png';
import anrari from '../../assets/anrari.png';
import urvya from '../../assets/urvya.png';
import fixhelpia from '../../assets/fixhelpia.png';
import Image6 from '../../assets/img01-1.png';
import Image7 from '../../assets/AI.png';
import Image8 from '../../assets/CRM.png';
import Image9 from '../../assets/customize.png';
import Image10 from '../../assets/best.png';
import Image11 from '../../assets/quality.png';
import Image12 from '../../assets/payment.png';
import Image13 from '../../assets/icon01.png';
import Image18 from '../../assets/icon02.png';
import Image19 from '../../assets/icon03.png';
import Image20 from '../../assets/icon04.png';
import Image14 from '../../assets/voip.png';
import Image15 from '../../assets/voip.png';
import Image16 from '../../assets/dialer.png';
import Image21 from '../../assets/img-2.png';
import { AiOutlineCheck } from 'react-icons/ai';
// Carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// //  import Image17 from '../../assets/dialer.png';
// cardsection
import Image23 from '../../assets/blog01.png';
import Image24 from '../../assets/blog02.png';
import Image25 from '../../assets/blog03.png';
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';



const options = {
    responsiveClass: true,
    margin: 10,
    autoplay: true,
    slideBy: 1,
    dotsEach: true,
    navText: [
        "<div className='nav-btn prev-slide'></div>",
        "<div className='nav-btn next-slide'></div>",
    ],
    smartSpeed: 500,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },

        768: {
            items: 3,
        },
        1024: {
            items: 3,
        },
    },
};




const Home = () => {



    return (


        <Fragment>

            <section className="bannar_top pt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6} p-0>
                            <h1 className="donec mt-3">Boost Your Customer Experience with Advanced Cloud Contact Center Solutions</h1>
                            <p className="ban-text mt-1 pt-1">Experience the power of Cloud Telephony Services</p>
                            <Button variant="secondary" className="get15"><a href="/trial-demo"> <span style={{ 'color': '#fff' }} >Get Your Free Trial</span> </a></Button>{' '}
                        </Col>
                        <Col md={6} sm={12} lg={6} p-0>
                            <div className="shakebs">
                                <img src={Image2} alt="3422" className="element fig" width={{ 'width': '100%' }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container >
                    <Row className="mt-3">
                        <Col md={12} sm={12} lg={6}>
                            <h2 className="non">Why Choose Us</h2>
                            <p className="vestu mt-1 pt-1">What makes us different from others.</p>
                            <Row className="mt-3">
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image7} alt="3422" width="38" />&nbsp;Superior call quality</h3>
                                    <p className="cras">Crystal-clear voice and low latency.</p>
                                </Col>
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image8} alt="3422" width="38" />&nbsp;Advanced features</h3>
                                    <p className="cras">Call recording, IVR, call routing, and more.</p>

                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image9} alt="3422" width="38" />&nbsp; Easy integration</h3>
                                    <p className="cras">Quickly integrate with your existing systems.</p>
                                </Col>
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image10} alt="3422" width="38" />&nbsp;Affordable pricing</h3>
                                    <p className="cras">Cost-effective plans with flexible pricing.</p>

                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image11} alt="3422" width="38" />&nbsp;Reliable service</h3>
                                    <p className="cras">99.99% uptime and excellent customer support.</p>
                                </Col>
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image12} alt="3422" width="38" />&nbsp;Scalable solutions</h3>
                                    <p className="cras">Easily scale up or down as your business grows.</p>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image11} alt="3422" width="38" />&nbsp;Secure communication</h3>
                                    <p className="cras">End-to-end encryption for secure conversations.</p>
                                </Col>
                                <Col md={6} sm={12} lg={6} className="mt-2">
                                    <h3 className="visu"> <img src={Image12} alt="3422" width="38" />&nbsp;User-friendly interface</h3>
                                    <p className="cras">Intuitive and easy-to-use dashboard.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} sm={12} lg={6}>
                            <div className="shakse mt-3 pt-3">
                                <img src={Image6} alt="3422" width={{ 'width': '100%' }} className="element fig" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="post p-80">
                <div className="deiler pt-4">
                    <Container class="pt-5">
                        <h2 className="donec1 text-center">Everything you need for work, all in one place.</h2>

                        <p className=" ban-text1 text-center">We make it easy for businesses to get sending in a simple, secure, and streamlined way by setting you up with the right solutions.</p>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={12} lg={6} md={12}>

                                    <Tab.Content className="mt-5 pt-5 p-0">
                                        <Tab.Pane eventKey="first">
                                            <img src={Image14} alt="3422" width="100%" />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <img src={Image15} alt="3422" width="100%" />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="third">
                                            <img src={Image16} alt="3422" width="100%" />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            <img src={Image16} alt="3422" width="100%" />
                                        </Tab.Pane>


                                    </Tab.Content>
                                </Col>
                                <Col sm={12} lg={6} md={12}>

                                    <Row>
                                        <Nav variant="pills" className=" mt-5 pt-5 p-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" className="navpill-1"><p className="voip"><img src={Image13} alt="3422" width="40" />&nbsp;VoIP Service</p><p className="morbi">Revolutionize your communication with our reliable and cost-effective VOIP service. Say goodbye to traditional phone systems and hello to modern technology!</p></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" className="ani"><p className="voip"><img src={Image18} alt="3422" width="40" />&nbsp;Voice</p><p className="morbi">Experience seamless communication with our reliable voice service. Crystal clear audio and intuitive features to enhance your business operations.</p></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><p className="voip"><img src={Image19} alt="3422" width="40" />&nbsp;Messaging</p><p className="morbi">Messaging is a convenient way to stay connected with business clients and employers colleagues. Send quick messages, share photos or files, and keep conversations organized.</p></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth"><p className="voip"><img src={Image20} alt="3422" width="40" />&nbsp;Automation</p><p className="morbi">Automation is the use of technology to perform tasks without human intervention. It can help businesses save time and money while improving efficiency</p></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>


                    </Container>
                    <Container className="mors mt-5">
                        <Row>
                            <Col md={6} sm={12} lg={6} p-0>
                                <Button variant="primary" size="lg" active className="butons">
                                    Interactive Voice Response (IVR)
                                </Button>{' '}
                                <h2 className="non mt-5 pt-3">Professional Customer Greetings</h2>
                                <p className="vestu mt-2 mb-2">To All Your Customers</p>

                                <Row>
                                    <Col md={12} sm={12} lg={6} p-0>
                                        <h3 class="visu"><i className="tan"><AiOutlineCheck /></i> Customized Solution</h3>
                                    </Col>

                                    <Col md={12} sm={12} lg={6} p-0>
                                        <h3 class="visu"><i className="tan"><AiOutlineCheck /></i> Real time notification</h3>
                                    </Col>

                                </Row>

                                <Row className="mt-2">
                                    <Col md={12} sm={12} lg={6}>
                                        <h3 class="visu"><i className="tan"><AiOutlineCheck /></i> Multilevel IVR</h3>
                                    </Col>

                                    <Col md={12} sm={12} lg={6}>
                                        <h3 class="visu"><i className="tan"><AiOutlineCheck /></i> API Integration</h3>
                                    </Col>

                                </Row>
                                <Row className="mt-2">
                                    <Col md={12} sm={12} lg={6}>
                                        <h3 class="visu"><i className="tan"><AiOutlineCheck /></i> Unlimited user</h3>
                                    </Col>

                                </Row>
                            </Col>


                            <Col md={6} sm={12} lg={6}>
                                <img src={Image21} alt="3422" width={{ 'width': '100%' }} className="element-1" />
                            </Col>
                        </Row>



                    </Container>
                </div>
            </section>
            <section className="mt-5">
                <Container>
                    <h2 className="non11">Our Happy Clients</h2>
                    <p className="lead1 mt-4 text-center">Trusted by thousands of clients to enable their telephony requirement. Meet few of our clients</p>
                </Container>
            </section>


            <section>
                <Container>
                    <OwlCarousel className='owl-theme' loop margin={1} nav {...options}>

                        <div class='item'>
                            <div className="card-align">
                                <img src={Rirabhlogo} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">Rirabh has been happy to work with Callerspot Communications for the past two years. <br /> We like their flexibility in developing strategic solutions and customising them to our needs.</p>

                            </div>
                        </div>
                        <div class='item'>
                            <div className="card-align">
                                <img src={rirabhinter} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">Rirabh Interiors has been working with Callerspot for over two years, and their customer support has been outstanding.   <br /> They are kind, personable, and constantly available to answer questions. We strongly advise you to collaborate with them.</p>

                            </div>
                        </div>
                        <div class='item'>
                            <div className="card-align">
                                <img src={anrari} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">Working with CallerSpot! has always been a great experience for me. CallerSpot has always supplied favourable suggestions as well as the greatest pricing for services.  <br /> I'm really satisfied with the result afterbooking. I strongly advise you to choose CallerSpot as your cloud telephony service provider.</p>

                            </div>
                        </div>
                        <div class='item'>
                            <div className="card-align">
                                <img src={urvya} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">Callerspot's Click2Call Solution has provided us with a solution to solve our worries about our customer informations are not being shown. <br /> The integration runs well and has improved performance.</p>

                            </div>
                        </div>
                        <div class='item'>
                            <div className="card-align">
                                <img src={fixhelpia} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">We've been working with CallerSpot for about a year and have had a positive experience.  <br /> The CallerSpot crew is sympathetic and always willing to assist in any scenario.</p>

                            </div>
                        </div>
                        <div class='item'>
                            <div className="card-align">
                                <img src={logo} style={{ width: "163px" }} />
                                <p className="dedicatess text-center mt-4">Our organisation has benefited from CallerSpot's most current solution. <br /> The integration procedure went smoothly, and we were able to complete the job swiftly. We were able to resolve the connectivity issue thanks to the solution provided.</p>

                            </div>
                        </div>
                    </OwlCarousel>
                </Container>
            </section>

            <section>
                <Container className="tecnno">
                    <div className="display-4 text-center mt-5 ">Our USP</div>
                    <p className="lead1 mt-3 text-center">Here are few smart features</p>
                    <Row className="bottom-nav mt-5">
                        <Col md={4} sm={6} xs={12} lg={3} className="">
                            <p className="voips"><img src={Image26} alt="3422" width="40" />&nbsp;IVR Service</p>
                            <ul className="flex-column voipsul">
                                <li className="arc">AI Integration</li>
                                <li className="arc">Google, Amazon, IBM AI Integration</li>
                                {/* <li className="arc">Agent can see customer</li> */}
                                <li className="arc">Number on incoming call</li>
                                <li className="arc">CRM/Third Part Integration</li>
                                <li className="arc">Quick Customization Possible</li>
                                <li className="arc">Chat Bot Integration</li>
                                <li className="arc">Free Lead Management System</li>
                                <li className="arc">Marketing Automation Integration</li>
                            </ul>
                        </Col>
                        <Col md={4} sm={6} xs={12} lg={3}>
                            <p className="voips"><img src={Image27} alt="3422" width="40" />&nbsp; BULK VOICE CALLS</p>
                            <ul className="flex-column voipsul">
                                <li className="arc">Custom Reserved Caller ID</li>
                                <li className="arc">Call Back Feature</li>
                                <li className="arc">Wait till noise feature</li>
                                <li className="arc">On Extension Lead</li>
                                <li className="arc">On Answer SMS</li>
                                <li className="arc">On Extension SMS</li>
                                <li className="arc">2 Way (Incoming & Outgoing)</li>
                            </ul>
                        </Col>
                        <Col md={4} sm={6} xs={12} lg={3}>
                            <p className="voips"><img src={Image28} alt="3422" width="40" />&nbsp;CHAT BOT</p>
                            <ul className="flex-column voipsul">
                                <li className="arc">Whatsapp and Web Support</li>
                                <li className="arc">Super Easy to create and operate</li>
                                <li className="arc">AI and Scripted bots</li>
                                <li className="arc">Manual Agent Transfer Support</li>
                                <li className="arc">Whatsapp Sharing between multiple Agents</li>
                                <li className="arc">Internal Team communication inside chat</li>
                                <li className="arc">Automatic Sales Agent Whatsapp Tracking</li>
                                <li className="arc">Multi-Media support</li>
                                <li className="arc">API Support</li>
                            </ul>

                        </Col>
                        <Col md={4} sm={6} xs={12} lg={3}>
                            <p className="voips "><img src={Image28} alt="3422" width="40" />&nbsp;AUTO DIELER</p>
                            <ul className="flex-column voipsul">
                                <li className="arc">App Based Dialler so no OTC</li>
                                <li className="arc">Highly Scalable</li>
                                <li className="arc">Inbound and Outbound call tracking</li>
                                <li className="arc">Two Way Call Masking</li>
                                <li className="arc">Internal Team communication inside chat</li>
                                <li className="arc">Configure call back on masked number</li>
                                <li className="arc">Multiple policy assignment</li>
                                <li className="arc">CRM Integrations</li>
                            </ul>

                        </Col>
                    </Row>

                </Container>
            </section>

        </Fragment>
    );

};

export default Home;
