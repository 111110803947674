import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle from '../../assets/education/EducationIndustry.svg';
import cloudcommunication from '../../assets/education/cloudcommunication.svg';
import CallRouting from '../../assets/education/TrackMarketingROIEffectively.svg';
import VideoPlatform from '../../assets/education/VideoPlatform.svg';
import EasilyGeneraLeads from '../../assets/education/EasilyGenerateLeads.svg';
import RemoteWorking from '../../assets/education/RemoteWorking.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
const Education = () => {

    return (
        <>

            <Helmet>
                <title>Education Industry - Cloud Telephony Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="CallerSpot provides cloud telephony solutions for the education industry, offering features like IVR, call routing, and analytics to improve communication and streamline operations. Optimize your education business with CallerSpot"

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Education Industry</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Optimize Your Call Routing and Improve Efficiency with our Interactive Voice Response (IVR) Service.</p> */}
            </div>

            <section className="bgcolor">


                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2">How Is Callerspot Helping The Education Industry?</h2>

                            <p className="passages1 mt-3">Offer a personalized journey to your customers and engage with them on their preferred
                                platform or channel Cloud communication solutions meet all the requirements of the
                                students and stakeholders in the education sector. It brings together the staff,
                                instructors, and students from any city, at any time to enable full e-learning. <br /> We, at Callerspot, develop a cloud telephony network that is ideal for businesses of all
                                sizes. Our company has been providing reliable and futuristic communication solutions
                                at affordable price ranges. We look forward to ensuring more effectiveness of the
                                provided solutions. <br /> With the help of a streamlined engagement platform and a team of capable support
                                staff, we ensure remote learning for the students. Our reliable solutions will enable you
                                to simplify corporate communication and engage customers on their preferred channels
                                by providing a tailored experience. </p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                    </Row>
                    <Row className="mt-4 reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={cloudcommunication} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>

                        <Col md={6} sm={12} lg={6}>

                            <h2 className="text-2"> Check out the benefits of cloud
                                communication.</h2>

                            <ul>
                                <li className="passages1 mt-3">It helps in answering all incoming calls with pre-recorded messages</li>
                                <li className="passages1">It creates a substitute academic portal and boosts promotional activities</li>
                                <li className="passages1"> It conducts result-oriented surveys and runs in house-campaigns</li>
                            </ul>
                            <p className="passages1">We, at Callerspot, develop a cloud telephony network that is ideal for small, medium, and large players of Education Industry. Our company has been providing reliable and futuristic communication solutions at affordable price ranges. We are gradually trying to enhance the effectiveness of our provided solutions. Driven by a capable team and streamlined engagement platform, we have been able to ensure remote learning for the students. Our reliable solutions are designed to simplify corporate communication and engage customers by providing a custom-made experience.</p>

                        </Col>


                    </Row>


                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Needs We Suffice for Education Industry</h2>
                            <p className="page">Facilitate remote learning of students on a streamlined engagement platform with an
                                efficient support team.</p>
                        </div>
                    </Row>


                    <Row  className="mt-4">
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-4">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '288px'}}>
                                    <Card.Img variant="top" src={EasilyGeneraLeads} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Sales and Lead Generation</Card.Title>
                                        <Card.Text>
                                            Automate the sales and lead creation process with Cloud Contact Centre, Lead
                                            Management, and Missed Call Solution, you can enhance sales with a focused strategy
                                            adapted to your needs.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-4">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '288px'}}>
                                    <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Track Marketing ROI</Card.Title>
                                        <Card.Text>
                                            Connect Missed Call Solution and Virtual Business Numbers to your online and offline
                                            marketing activities to create a single multi-channel platform for tracking and monitoring.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-4">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '288px'}}>
                                    <Card.Img variant="top" src={RemoteWorking} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Remote Working</Card.Title>
                                        <Card.Text>
                                            Increasing efficiency with smart remote work Your consumers will have access to Cloud
                                            Communication Technology and a Video Platform from anywhere, at any time.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} className="mt-4">
                            <div md={4} sm={2} lg={4}>
                                <Card className="red" style={{'minHeight': '288px'}}>
                                    <Card.Img variant="top" src={VideoPlatform} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Video Platform</Card.Title>
                                        <Card.Text>
                                            Allow video meetings between students and professors to take place from anywhere at
                                            any time by simply providing a unique link for seamless online learning.
                                        </Card.Text>
                                    </Card.Body>
                                    {/*  */}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );


};
export default Education;