import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button, Carousel, Form } from "react-bootstrap";
import index from '../TryForFree/index.css';
import PhoneInput from 'react-phone-number-input';
import emailjs from "@emailjs/browser";
import signup from '../../assets/signup_bg.png';

import bullet_icon from '../../assets/bullet_icon.png';

const TrialDemo = () => {


    const [phone, setPhone] = useState("");


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_ug0umhk",
                "template_dl8ruhs",
                form.current,
                "qgwqpK8jkfC37OZJD"
            )
            .then(
                (result) => {
                    console.log(result);
                    const show = document.getElementById("messages");
                    show.innerHTML = " Your Response has been sent. &#128077; ";
                    form.current.reset();
                    setPhone("");
                    setTimeout(() => {
                    }, 10000);
                },
                (error) => {
                    console.log(error.text);
                }
            );

    };



    return (
        <>

            <Helmet>
                <title>Call Center Software | Cloud Call Center Solution | CallerSpot</title>
                <meta
                    name="description"
                    content="Cloud call center software helps to handle the large volume of calls. Callerspot provides cloud contact center solutions to business to improve customer experience."

                />
            </Helmet>

            <Container>
                <Row className="ptb-60">
                    <Col xl={6} lg={6} md={12} sm={12} className="listing" >
                        <h1>What can you do with the dashboard?</h1>
                        <ul>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Build quicker communication flows </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Design your agent view </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Communicate with customers using free SMS & call credits </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Customize your dashboard to fit your unique business needs </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Set up automation with bots and AI </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Get access to 24/7 customer support and resources </p> </div> </li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Streamline your workflow and reduce response times </p> </div></li>
                            <li> <img src={bullet_icon} alt="list png img" /> <div> <p> Manage multiple channels like SMS, chat, voice, email and more </p> </div></li>
                        </ul>
                        <div className="signup">
                            <img src={signup} alt="" className="w-100" />
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className="mt-4 formsection">
                        <h2>Hi there! Let's get you started</h2>
                        <p className="mt-2" style={{'fontSize':'16px'}}>Create your account and go live in 2 mins</p>
                        <br/>
                        <form>
                            <div className="mb-4">
                                <span className="wpcf7-form-control-wrap ExoName">
                                    {/* <label>/label> */}
                                    <Form.Label>Name *</Form.Label>
                                    <input type="text" className="form-control" placeholder="Enter Your Name" />
                                </span>
                            </div>
                            <div className="mb-4">
                                <span className="wpcf7-form-control-wrap ExoName">
                                    {/* <label></label> */}
                                    <Form.Label>Company *</Form.Label>
                                    <input type="text" className="form-control" placeholder="Enter Company Name" />
                                </span>
                            </div>
                            <div className="mb-4">
                                <span className="wpcf7-form-control-wrap ExoName">
                                    <Form.Label>Email *</Form.Label>
                                    <input type="text" className="form-control" placeholder="Enter Your Email ID" />
                                </span>
                            </div>
                            <div className="mb-4">
                                <Form.Label>Phone Number *</Form.Label>
                                <PhoneInput className=""
                                    placeholder="Enter First Country Number"
                                    name="to_number"
                                    value={phone}
                                    maxlength="15"
                                    onChange={(phone) => setPhone(phone)} />
                            </div>
                            <div className="mb-4">
                                <span className="wpcf7-form-control-wrap ExoName">
                                    <Form.Select aria-label="Default select example">
                                        <option>Products Interested *</option>
                                        <option value="1"> Browser Calling </option>
                                        <option value="2"> ExoVerify </option>
                                        <option value="3"> Voice </option>
                                        <option value="4"> Voice Streaming </option>
                                        <option value="5"> SMS </option>
                                        <option value="6"> SMS Campaigns </option>
                                        <option value="6"> Call Campaigns </option>
                                        <option value="6"> Lead Assist </option>
                                        <option value="6">  WhatsApp  </option>
                                    </Form.Select>
                                </span>
                            </div>
                            <div className="mb-4">
                                <span className="wpcf7-form-control-wrap ExoName">
                                    <Form.Select aria-label="Default select example">
                                        <option> Industry </option>
                                        <option value="1"> Food & Beverage </option>
                                        <option value="2"> E-Commerce </option>
                                        <option value="3"> Marketplaces </option>
                                        <option value="4"> Hyperlocal </option>
                                        <option value="5"> IT services </option>
                                        <option value="6"> Education </option>
                                        <option value="6"> Manufacturing </option>
                                        <option value="6"> BFSI & NBFC </option>
                                        <option value="6"> Hospitality </option>
                                        <option value="6"> NGO & Social Enterprises </option>
                                        <option value="6"> FMCG </option>
                                        <option value="6"> Real Estate </option>
                                        <option value="6"> Logistics </option>
                                        <option value="6"> HR & Recruitment </option>
                                        <option value="6"> Marketing & Advertising </option>
                                        <option value="6"> Healthcare </option>
                                        <option value="6"> Travel </option>
                                        <option value="6"> Others </option>
                                    </Form.Select>
                                </span>
                            </div>
                            

                            <Button variant="secondary" className="submit-button" size="lg" type="submit" value="Send">
                                Submit
                            </Button>
                        </form>
                    </Col>
                </Row>

            </Container>

        </>
    );



};
export default TrialDemo;