import React from "react";
import Button from 'react-bootstrap/Button';
import { Container, Row, Col, Tab, Nav, Card } from "react-bootstrap";
import Image1 from "../assets/callerspot1.svg";
import Image2 from "../assets/facebook.png";
import Image3 from "../assets/instagram.png";
import Image4 from "../assets/twitter.png";
import Image5 from "../assets/linkedin.png";
import Image6 from "../assets/pinterest.png";
import Image7 from "../assets/youtube.png";
import "../component/style.css";




const Footer = () => {
    return (
        <>
            <section className="devops">
                <Container>
                    <div className="feauture-img">
                        <Row>
                            <Col>
                                <h4 className="sell sellh4 mt-4 ml-4">Smarter conversations with CallerSpot</h4>

                            </Col>

                            <Col>
                                <div className="buton-text" style={{'textAlign':'center'}}>
                                    <p className="sells mt-4">&ensp;
                                        <Button variant="danger" className="danger">Learn More</Button>{' '}</p>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>


                <div className="footer_bn">
                    <Container className="ban-top1">
                        <Row className="bottom-nav pt-2 mt-2 pb-4">
                            <Col md={6} sm={12} lg={4} className="pt-3 mt-4 col-12">
                                <ul>
                                    <img src={Image1} alt="3422" width={'50%'} />
                                    <p className="manys mt-2">Welcome to Callerspot, a leading cloud telephony service provider dedicated to providing businesses of all sizes with reliable, feature-rich, and affordable communication solutions. We look forward to developing a cloud telephony network that is reachable to every business. <a href="/about-us">Read More</a> </p>
                                    {/* <h6 className="social_icons mt-2">Let's do it!</h6> */}
                                    <div className="social-icons">
                                        <p className="mark mt-3"> <a href="https://www.facebook.com/callerspot"> <img src={Image2} alt="3422" /> </a></p>
                                        <p className="mark mt-3"> <a href="https://www.instagram.com/callerspot/"> <img src={Image3} alt="3422" /> </a> </p>
                                        <p className="mark mt-3"> <a href="https://twitter.com/callerspotindia"> <img src={Image4} alt="3422" /> </a> </p>
                                        <p className="mark mt-3"> <a href="https://www.linkedin.com/company/callerspot">  <img src={Image5} alt="3422" /> </a> </p>
                                        <p className="mark mt-3"> <a href="https://www.pinterest.com/callerspot/"> <img src={Image6} alt="3422" /> </a> </p>
                                        <p className="mark mt-3"> <a href="https://www.youtube.com/@callerspot"> <img src={Image7} alt="3422" /> </a> </p>
                                       
                                    </div>
                                </ul>
                            </Col>
                            <Col md={3} sm={3} xs={3} lg={2} className="pt-3 col-6 mt-4">

                                {/* <ul className="product-name"> */}
                                <ul>
                                    <li className="title social_icons">MENU</li>
                                    <li className="nav1"><a href="/about-us">About us </a> </li>
                                    <li className="nav1"><a href="/contact-us">Contact us</a> </li>
                                    {/* <li className="nav1"><a href="#">Careers</a></li> */}
                                    {/* <li className="nav1"><a href="/blog">Blog</a></li> */}
                                    <li className="nav1"><a href="/term-and-condition">Terms and Condition</a></li>
                                    <li className="nav1"><a href="/privacy-policy">Privacy Policy</a></li>
                                    {/* <li className="nav1"><a href="#">Refund Policy</a></li> */}
                                </ul>
                            </Col>

                            <Col md={3} sm={3} xs={3} lg={2} className="pt-3 col-6 mt-4">
                                <ul>
                                    <li className="title social_icons">VOICE</li>

                                    <li className="nav1"><a href="/cloud-center">Cloud Contact Center</a></li>
                                    <li className="nav1"><a href="/virtual-number">Virtual Number</a></li>
                                    <li className="nav1"> <a href="/ivr-Service">IVR Solution</a> </li>
                                    <li className="nav1"> <a href="/number-masking">Number Masking</a> </li>
                                    <li className="nav1"> <a href="/lead-management">Lead Management</a> </li>
                                    <li className="nav1"> <a href="/missed-call">Missed Call</a> </li>
                                    <li className="nav1"> <a href="/toll-free-number">Toll Free Number</a> </li>
                                    {/* <li className="nav1"> <a href="#">Automated</a> </li> */}
                                    <li className="nav1"> <a href="/automated-outbond-calling">Automated Calling</a> </li>
                                    <li className="nav1"> <a href="/click-to-call">Call to Click</a> </li>
                                    <li className="nav1"> <a href="/auto-dialer">Auto Dialer</a> </li>
                                </ul>
                            </Col>

                            <Col md={3} sm={3} xs={3} lg={2} className="pt-3 col-6 mt-4">
                                <ul>
                                    <li className="title social_icons">SMS</li>
                                    <li className="nav1"> <a href="/promotional-sms">Promotional SMS</a></li>
                                    <li className="nav1"> <a href="/transactional-sms">Transactional SMS</a></li>
                                    <li className="nav1"> <a href="/whatsapp-business-api">WhatsApp Business API</a></li>
                                </ul>
                                <ul className="mt-2">
                                    <li className="title social_icons">AUTOMATION</li>
                                    <li className="nav1"> <a href="/text-to-speech">Text-To-Speech</a>  </li>
                                    <li className="nav1"> <a href="/all-chatBot">AI ChatBot/VoiceBot</a> </li>
                                    <li className="nav1"> <a href="/speech-analytics">Speech Analytics</a> </li>
                                </ul>

                            </Col>

                            <Col md={3} sm={3} xs={3} lg={2} className="pt-3 col-6 mt-4">
                                <ul>
                                    <li className="title social_icons">INDUSTRY</li>

                                    <li className="nav1"><a href="/education">Education</a></li>
                                    <li className="nav1"> <a href="/healthcare">Healthcare</a> </li>
                                    <li className="nav1"> <a href="/retail-ecommerce">Retail & Ecommerce</a> </li>
                                    <li className="nav1"> <a href="/banking"> Banking & Finance</a> </li>
                                    <li className="nav1"> <a href="/transportation-logistics">Transportation & Logistics</a> </li>
                                    <li className="nav1"> <a href="/manufacturing">Manufacturing</a> </li>
                                </ul>
                            </Col>

                        </Row>
                    </Container>

                </div>

            </section>
            <section>
                <div className="footer-bottam">
                    <p className=" bottom-text1 text-center mt-2">© 2023 All Rights Reserved callerspot.com </p>
                </div>
            </section>

        </>
    );
};
export default Footer;