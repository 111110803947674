import React, { useState, useEffect } from "react";
import './App.css';
import "./responsive.css";
import "./sidebar.css";
import "./component/navHeader/nav.css";
import { useLocation } from "react-router";
// import AppHeader from './component/navHeader/Appheader';
import Header from './component/navHeader/navbar';

import Footer from './component/footer';
import Routing from "./router";
import { useMediaQuery } from "react-responsive";
import Aside from "./component/navHeader/sidebar";
import { Fragment } from 'react';


const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const handleToggle = () => {
    setToggled(prev => !prev);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Fragment>
   {/* {isTabletOrMobile ? (
          <Aside
            // collapsed={collapsed}
            // toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleToggle={handleToggle}
          />
        ) : (
          ""
        )} */}
        <Header/>
      {/* <AppHeader isMobile={isTabletOrMobile}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar} /> */}
        <Routing />
      <Footer />
    </Fragment>
  );
}

export default App;
