import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/aocs/AutomatedOutboundCalling.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import AutoDialingfacility from '../../assets/aocs/AutoDialingfacility.svg';
import SMSintegrations from '../../assets/aocs/SMSintegrations.svg';
import OutboundIVRautovoiceresponse from '../../assets/aocs/OutboundIVRautovoiceresponse.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import EnabletheCloudBasedOutboundCalls from '../../assets/aocs/EnabletheCloudBasedOutboundCalls.svg';
import CompliancefromTRAI from '../../assets/aocs/CompliancefromTRAI.svg';
import GettheTargetAudience from '../../assets/aocs/GettheTargetAudience.svg';
import Easyinstallactivate from '../../assets/aocs/Easyinstallactivate.svg';
import Getbettercallerexperience from '../../assets/aocs/Getbettercallerexperience.svg';
import ImprovetheROI from '../../assets/aocs/ImprovetheROI.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };



    return (
        <>

            <Helmet>
                <title>
                Automated Calls | Call Broadcast | Voice Blast | CallerSpot
                </title>
                <meta
                    name="description"
                    content="Outreach thousands of your customers in one go. Try CallerSpot's automated calls services to broadcast reminders, updates and offers to your customers on a click."
                />

            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white" style={{ 'lineHeight': '40px' }}>Automated Outbound Calling Solution</h1>
                {/* <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Never miss a potential lead by routing your calls to your personal phone number with a Virtual Number.</p> */}
            </div>

            <section className="bgcolor">

                <section className="">
                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">What Is An Automated Outbound Calling Solution?</h2>

                                <p className="passages1 mt-3">Automated calling offers a recording facility where any business can record the interaction voice of the customer or caller without any human intervention. It allows for analyzing customer voice interactions.</p>
                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>


                    </Container>
                </section>

                <section className="mt-5">
                    <Row>
                        <div className="text-center">
                            <h2 className="featured">Features of Automated Outbound Calling Solution:</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>
                </section>

                <section className="mt-5">
                    <Container>
                        <Row>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">

                                <Card className="red" style={{'minHeight': '253px'}}>
                                    <Card.Img variant="top" src={AutoDialingfacility} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Auto Dialing facility</Card.Title>
                                        <Card.Text>It reduces the agent idle time via the auto dialing process. Obtain local numbers worldwide for improved international communication.
                                        </Card.Text>
                                    </Card.Body>

                                </Card>

                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">

                                <Card className="red" style={{'minHeight': '253px'}}>
                                    <Card.Img variant="top" src={OutboundIVRautovoiceresponse} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>Outbound IVR auto voice response</Card.Title>
                                        <Card.Text>
                                            Get notifications & reminders about the service, due dates, products or others.
                                        </Card.Text>
                                    </Card.Body>

                                </Card>

                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                <Card className="red" style={{'minHeight': '253px'}}>
                                    <Card.Img variant="top" src={SMSintegrations} className="mt-3" />
                                    <Card.Body>
                                        <Card.Title>SMS integrations</Card.Title>
                                        <Card.Text>
                                            Get an auto SMS facility that would be integrated with follow-up calls.
                                        </Card.Text>
                                    </Card.Body>

                                </Card>

                            </Col>
                        </Row>

                    </Container>
                </section>
            </section>



            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Automated Outbound Calling</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={EnabletheCloudBasedOutboundCalls} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Enable the Cloud-Based Outbound Calls </h3>
                                    <p>It generates a communication system under the cloud-based system.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={CompliancefromTRAI} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4"> Compliance from TRAI</h3>
                                    <p>It follows all the regulations of the TRAI.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={GettheTargetAudience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Get the Target Audience</h3>
                                    <p> Schedule auto-set all the calls for any location.</p>
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={ImprovetheROI} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Improve the ROI</h3>
                                    <p>Update the latest running offers, and exciting deals to improve your ROI.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Easyinstallactivate} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Easy to install & activate</h3>
                                    <p>Get the simple plug & integration facility.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Getbettercallerexperience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Get a better caller experience</h3>
                                    <p>Provide customized greetings & amazing hold music for the caller.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );


};
export default Virtual;