import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import Rectangle from '../../assets/tts/TextToSpeech.svg';
import dialer from '../../assets/dialer-screen1.svg';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/tts/Pitchcontrol.svg';
import OpticalCharacterRecognition from '../../assets/tts/OpticalCharacterRecognition.svg';
import Readingdifficulties from '../../assets/tts/Readingdifficulties.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/tts/Voicesimultaneously.svg';
import SpeechCustomization from '../../assets/tts/SpeechCustomization.svg';
import Languagesandaccents from '../../assets/tts/Languagesandaccents.svg';
import AlertsReminders from '../../assets/tts/AlertsReminders.svg';
import Costeffective from '../../assets/tts/Costeffective.svg';
import VoiceExperience from '../../assets/tts/VoiceExperience.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Rectangle952 from '../../assets/Rectangle952.svg';

const Virtual = () => {


    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

    

    return (
        <>

<Helmet>
        <title>
        Best Text To Speech Platform for Businesses - Automated TTS Software
        </title>
        <meta
            name="description"
            content="Discover the Ultimate Text-to-Speech Platform for Businesses! Experience the Power of Automated TTS Software for Effortless Voice Generation and Enhanced Customer Engagement. Unlock a New Level of Productivity and Communication Today"
        />

    </Helmet>

            <div className="service-bannar1 service-bannar2">
                <h1 className="education text-center text-white" style={{'lineHeight':'40px'}}>Text-To-Speech (TTS) Solution</h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Advanced Text-to-Speech applications to help you scale your business</p>
            </div>

            <section className="bgcolor">

                    <Container>
                        <Row>
                            <Col md={6} sm={12} lg={6}>

                                <h2 className="text-2">What is Text-To-Speech ?</h2>

                                <p className="passages1 mt-3">Convert written texts into a speech format with the advancement in voice recognition technology! You can now get more human-like sound to offer instant gratification via greetings on your IVR calls. Text-to-speech (known as TTS or read aloud technology) is a technology that reads digital text as human-like voice messages. It takes words on a digital device or computer and convert them into audio just by click of a button.</p>
                            </Col>


                            <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                                <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                            </Col>

                        </Row>


                    <Row>
                        <div className="text-center">
                            <h2 className="featured">Features of Text-To-Speech (TTS) Solution</h2>
                            {/* <p className="page">Handle All Your Business Calls Remotely</p> */}
                        </div>
                    </Row>
            
                        <Row>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                {/* <div md={4} sm={2} lg={4}> */}
                                    <Card className="red" style={{'minHeight': '250px'}}>
                                        <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Pitch control</Card.Title>
                                            <Card.Text>
                                            TSS tools are ideal to control volume, speech rate, pronunciation, and emphasis on the text.
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                {/* </div> */}
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                {/* <div md={4} sm={2} lg={4}> */}
                                    <Card className="red" style={{'minHeight': '250px'}}>
                                        <Card.Img variant="top" src={Readingdifficulties} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title> Reading difficulties</Card.Title>
                                            <Card.Text>
                                            TTS tools are based on assistive technologies and helps people with difficulties in reading
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                {/* </div> */}
                            </Col>
                            <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                                {/* <div md={4} sm={2} lg={4}> */}
                                    <Card className="red" style={{'minHeight': '250px'}}>
                                        <Card.Img variant="top" src={OpticalCharacterRecognition} className="mt-3" />
                                        <Card.Body>
                                            <Card.Title>Optical Character Recognition</Card.Title>
                                            <Card.Text>
                                            Text-to-speech tools are designed to be used with optical character recognition (OCR).
                                            </Card.Text>
                                        </Card.Body>
                                        {/*  */}
                                    </Card>
                                {/* </div> */}
                            </Col>
                        </Row>
                        
                    </Container>
                </section>
       
           
            

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Benefits of Text-To-Speech (TTS) Solution</h2>
                            {/* <p className="pare mt-1">Manage a cost-effective, customizable smart solution for the flexibility of your business.</p> */}
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                    <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Voice simultaneously</h3>
                                    <p>TTS enables the users to see text and hear the same voice at the same time.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={VoiceExperience} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Voice Experience</h3>
                                    <p>TSS offers the users human-like voice experience with normal Indian accent.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Languagesandaccents} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Languages and accents</h3>
                                    <p>TSS is based on global languages, including Hindi, Spanish, Bengali, and English.</p>
                                </Col>
                            </Row>

                        </Col>
                    
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Costeffective} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Cost-effective</h3>
                                    <p>TSS has flexible and affordable pricing options to the users.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={SpeechCustomization} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Speech Customization</h3>
                                    <p>TSS enables the users to customize the speech as per the product/service and campaign nature.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12} className="mt-3">
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={AlertsReminders} style={{ 'height': '46px' }} />
                                    </div>
                                </Col>
                                 <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Alerts or Reminders</h3>
                                    <p>TSS enables the users to send automated reminders for due dates, appointments, and events.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>


        
            {/* <section className="mt-5">
                <Container>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                    <Row className="mt-2">
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className="col-6" md={6} sm={6}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are virtual phone numbers?</Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Row>
                </Container>
            </section> */}

        </>
    );


};
export default Virtual;