import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button, Carousel, Form } from "react-bootstrap";
import index from '../TrialDemo/index.css';
import emailjs from "@emailjs/browser";
import PhoneInput from 'react-phone-number-input';
import Requestimg from '../../assets/request-demo-border.svg';

const TrialDemo = () => {
    const [phone, setPhone] = useState("");


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_65xapzr",
                "template_9aj3z3f",
                form.current,
                "qgwqpK8jkfC37OZJD"
            )
            .then(
                (result) => {
                    console.log(result);
                    const show = document.getElementById("messages");
                    show.innerHTML = " Your Response has been sent. &#128077; ";
                    form.current.reset();
                    setPhone("");
                    setTimeout(() => {
                    }, 10000);
                },
                (error) => {
                    console.log(error.text);
                }
            );

    };



    return (
        <>

            <Helmet>
                <title>Call Center Software | Cloud Call Center Solution | CallerSpot</title>
                <meta
                    name="description"
                    content="Cloud call center software helps to handle the large volume of calls. Callerspot provides cloud contact center solutions to business to improve customer experience."

                />
            </Helmet>


            <section className="backbanner">
                <Container>
                    <Row className="ptb-60">
                        <Col xl={6} lg={6} md={12} sm={12} className="left-80">
                            <div className="request-card requestdemo request-card-move-lg">
                                <div className="tab-content persional-details productdetails" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="personal">
                                        <div className="wpcf7" id="wpcf7-f424050-o1">
                                            <form ref={form} onSubmit={sendEmail}>
                                                <div className="mb-4">
                                                    
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="to_name"
                                                        placeholder="Name"

                                                    />
                                                </div>
                                                <div className="mb-4">
                                                   
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="to_email"
                                                        placeholder="Work Email"

                                                    />

                                                </div>
                                                <div className="mb-4">
                                                    <PhoneInput className="ml-2" 
                                                        placeholder="Enter First Country Number"
                                                        name="to_number"
                                                        value={phone}
                                                        maxlength="15"
                                                        onChange={(phone) => setPhone(phone)} />
                                                </div>
                                                <div className="mb-4">

                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        name="to_compant_name"
                                                        placeholder="Company Name"

                                                    />

                                                </div>
                                                <div className="mb-4">
                                                    <span className="wpcf7-form-control-wrap ExoName">


                                                        <Form.Group className="mb-3" controlId="formBasicServices">

                                                            <Form.Select
                                                                aria-label="Select services"
                                                                name="job_level">
                                                                <option>Job Level</option>
                                                                <option>Executive/ C-Level</option>
                                                                <option>Vice President</option>
                                                                <option>Director</option>
                                                                <option>Manager</option>
                                                                <option>Freelancer</option>
                                                                <option>Student</option>
                                                            </Form.Select>
                                                        </Form.Group>


                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <span className="wpcf7-form-control-wrap ExoName">


                                                        <Form.Group className="mb-3" controlId="formBasicServices">

                                                            <Form.Select
                                                                aria-label="Select"
                                                                name="product">
                                                                <option>Product Interested</option>
                                                                <option>AppToApp Calling</option>
                                                                <option>Contact Center Solution</option>
                                                                <option>Conversational AI</option>
                                                                <option>SMS</option>
                                                                <option>Voice</option>
                                                                <option>WhatsApp</option>
                                                            </Form.Select>
                                                        </Form.Group>

                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <span className="wpcf7-form-control-wrap ExoName">


                                                        <Form.Group className="mb-3" controlId="">

                                                            <Form.Select
                                                                aria-label="Select services"
                                                                name="services">
                                                                <option>Select Department</option>
                                                                <option>Finance</option>
                                                                <option>Contact Center Solution</option>
                                                                <option>Marketing</option>
                                                                <option>Operations</option>
                                                                <option>Sales</option>
                                                                <option>Supply Chain</option>
                                                                <option>Procurement</option>
                                                                <option>Others</option>
                                                            </Form.Select>
                                                        </Form.Group>


                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        name="message"
                                                        placeholder="Your requirement in brief"
                                                    />
                                                </div>
                                                <br />
                                                <span id="messages" className="message-sent" style={{ color: 'red' }}></span>

                                                <Button variant="secondary" className="submit-button" size="lg" type="submit" value="Send">
                                                    Submit
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <h1>Request a demo:</h1>
                            <img src={Requestimg} alt="" className="w-100" />
                            <p>Learn how Exotel can make your customer conversation streamlined and efficient – through innovative and patented solutions, powerful API integrations, and enterprise-grade security.</p>
                            <p>Your 1-on-1 demo will include:</p>
                            <div className="request-list">
                                <div className="circlenumber">1</div>
                                <label>Breakdown of the challenges faced by your team</label>
                            </div>
                            <div className="request-list">
                                <div className="circlenumber">2</div>
                                <label>A demo of how exotel can help you overcome these challenges</label>
                            </div>
                            <div className="request-list">
                                <div className="circlenumber">3</div>
                                <label>Answers to any questions you may have - including pricing, setup, integrations and more</label>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>


        </>
    );



};
export default TrialDemo;