import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Nav, Card, Button } from "react-bootstrap";
import Image26 from '../../assets/IVR1.png';
import Image27 from '../../assets/calls0.png';
import Image28 from '../../assets/chatbot.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cuate from '../../assets/cuate.svg';
import Accordion from 'react-bootstrap/Accordion';
import CallRouting from '../../assets/mcs/RunCampaignsSimultaneous.svg';
import Reportingandmonitoring from '../../assets/mcs/Reportingandmonitoring.svg';
import GetCompleteDetails from '../../assets/mcs/GetCompleteDetails.svg';
import Rectangle11 from '../../assets/Rectangle11(1).png';
import Vectorlogo from '../../assets/mcs/ConductAnySurvey.svg';
import Rectangle from '../../assets/mcs/Missedcall.svg';
import Missedcallsec from '../../assets/mcs/Missedcallsec.svg';
import SMSAlerts from '../../assets/mcs/SMSAlerts.svg';
import GetTheUpdateSingleCall from '../../assets/mcs/GetTheUpdateSingleCall.svg';
import EasilyGenerateLeads from '../../assets/mcs/EasilyGenerateLeads.svg';
import RealTimeDataInsights from '../../assets/mcs/RealTimeDataInsights.svg';
import Costeffective from '../../assets/mcs/Costeffective.svg';
import HelpsCustomerFeedback from '../../assets/mcs/HelpsCustomerFeedback.svg';
import Enhancedcustomerexperience from '../../assets/mcs/Enhancedcustomerexperience.svg';

const missedcall = () => {

    return (
        <>

            <Helmet>
                <title>Missed Call Service Provider | Missed Call Solutions | CallerSpot</title>
                <meta
                    name="description"
                    content="Make your campaign a big success at a low cost. Use CallerSpot's missed call service to collect leads, conduct surveys and increase customer engagement."

                />
            </Helmet>

            <div className="service-bannar1">
                <h1 className="education text-center text-white">Missed call Service </h1>
                <p className="text-center color" style={{ 'fontFamily': 'Nunito Sans' }}>Advanced Missed Call Solution to Optimize Marketing Campaigns</p>
            </div>

            <section className="bgcolor">


                <Container>
                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <h2 className="text-2">What Is Missed Call Service?</h2>
                            <p className="passages1 mt-4">Missed call service is a professional and automated web-based service that provides callers with
                                real-time notice of all calls received on their dedicated mobile/TFN (Toll-free phone number) or
                                Landline. It is a cloud-based, quick, and cost-effective method of collecting consumer data and
                                generating excellent leads for your company. The missed call solution allows you to manage
                                your marketing campaigns from anywhere, and each missed call results in a high-quality lead.
                                You may make use of the 24-hour assistance to experience hassle-free missed call service. You
                                may now receive fast SMS notifications for every missed call and real-time campaign changes
                                via a monitoring dashboard. Dashboard analytics gives accurate information about the
                                performance and success of the missed call campaign. On a single dashboard, you can now
                                keep track of all missed calls.
                                With missed call services, you can now conduct short polls, surveys, and engage customer
                                competition at no cost to the caller. With missed call service, you can also execute OTP
                                verification, COD confirmation, web user registration, and other key business processes. This
                                service demonstrates to be a wonderful and efficient marketing strategy without requiring a
                                significant investment of time and money.</p>
                        </Col>
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Rectangle} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>
                    </Row>
                    <Row className="mt-3 reverce">
                        <Col md={6} sm={12} lg={6} className="mt-5 text-center">
                            <img src={Missedcallsec} className="w-100" style={{ 'maxWidth': '400px' }} />
                        </Col>
                        <Col md={6} sm={12} lg={6}>
                            <h2 className="text-2">How does It work?</h2>
                            <p className="passages1 mt-4">You can just connect your delightful customers with just one missed call also, Missed call service
                                facilitates selecting a desired missed call number from the list and you can give a missed call to
                                your customer by the same number. It provides a missed call solution with collect customer
                                information &amp; online monitoring.
                                An Easy Guide to your delightful customer to resolve their concerns or queries faster &amp; make
                                them happy via Missed Call Service. <br /> Customers Can See Your Ads/Promotions: After watching your advertisement, Customers can
                                directly connect to your virtual number through a call. </p>

                            <ul className="mt-3">
                                <li className="passages1 ">Automated generated Call Back or SMS:</li>
                                <p className="passages1">
                                    The system will activate an automated callback or SMS facility to notify the customer after
                                    receiving any missed call.
                                </p>
                                <li className="passages1">Get leads on the spreadsheet:</li>
                                <p className="passages1">The contact information of all the callers will get a Google spreadsheet.</p>
                            </ul>


                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <div className="text-center">
                            <h2 className="featured">Missed Call Features</h2>
                        </div>
                    </Row>
                    <Row className="mt-4">
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={CallRouting} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Run Campaigns Simultaneous</Card.Title>
                                    <Card.Text>
                                        You may run unlimited campaigns with a free calling service.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={GetCompleteDetails} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Get Complete Details</Card.Title>
                                    <Card.Text>
                                        You will get comprehensive information about the missed call details on a Google spreadsheet.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={SMSAlerts} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>SMS Alerts</Card.Title>
                                    <Card.Text>
                                        After receiving the customer call, you can activate and send your information through SMS.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={Reportingandmonitoring} className="mt-3" />
                                <Card.Body>
                                    <Card.Title>Activate Missed Call On Toll-free Number</Card.Title>
                                    <Card.Text>
                                        The missed call service offers to send missed calls to the toll-free number.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={GetTheUpdateSingleCall} className="mt-3" />
                                <Card.Body>
                                    <Card.Title> Get The Update on a Single Call </Card.Title>
                                    <Card.Text>
                                        When a customer calls your number and hangs up after one ring, Our IVR-based system will
                                        automatically send them a message or call them back with information about your company or
                                        product.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={12} className="mt-3">
                            <Card className="red" style={{'minHeight': '267px'}}>
                                <Card.Img variant="top" src={EasilyGenerateLeads} className="mt-3" />
                                <Card.Body>
                                    <Card.Title> Easily Generate Leads </Card.Title>
                                    <Card.Text>
                                        The missed call helps to generate high-quality leads for the customers.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section >

            <section className="mt-5">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="benefit">Missed Call Service Benefits</h2>
                            
                        </div>
                    </Row>
                </Container>
            </section>


            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Enhancedcustomerexperience} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Customer Engagement</h3>
                                    <p>Using the Missed call alert, any service provider can engage their customer without much more
                                        effort and share their service information.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Vectorlogo} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Conduct Any Survey</h3>
                                    <p>It is the most difficult thing which has converted into easier by using the missed call facility.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={RealTimeDataInsights} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Real-Time Analytics</h3>
                                    <p>Get the real-time data of the customer that boosts the business in the current manner.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={Costeffective} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Cost Effective</h3>
                                    <p>Get the auto-reply through SMS when your customer will receive a missed call to your number.</p>
                                </Col>
                            </Row>

                        </Col>
                        <Col md={6} xl={4} lg={6} sm={12}>
                            <Row>
                                <Col md={2} lg={2} xl={2} sm={2} className="col-2">
                                    <div>
                                        <img src={HelpsCustomerFeedback} style={{height:'46px'}} />
                                    </div>
                                </Col>
                                <Col md={10} lg={10} xl={10} sm={10} className="col-10 benefitlist">
                                    <h3 className="h4">Helps Customer Feedback</h3>
                                    <p>Unlimited missed calls which were received by your customer. Also,
                                        you can use those phone calling numbers for voting activities, customer experience &amp; customer
                                        feedback.</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>


            <section className=" mt-5 pt-4">
                <Container>
                    <Row className="bg">
                        <Col md={12} sm={12} lg={12} xl={6} style={{'paddingTop':'40px','paddingBottom':'25px'}}>
                            <h2 className="white mt-3">How Can Missed Call Service Help to Boost the Business?</h2>
                            <h3 className="mt-4 number">Get Easy Update</h3>
                            <p className="par mt-2">Service call providers will get a quick update of all the missed calls and they can take follow-ups
                                by SMS.</p>
                            {/* <h2>Activate</h2> */}
                            <h2 className="mt-4 number">Customer Verification</h2>
                            <p className="par mt-2">Missed call alert is one of the best mediums to get OTPs for your customer verification. You can
                                verify any number by asking your customer using the missed call to them.</p>
                            <h2 className="mt-4 number">Registration</h2>
                            <p className="par mt-2">You can easily have registered anything from your customers by giving a missed call.</p>
                        </Col>
                        <Col md={12} sm={12} lg={12} xl={6} className="imggg" >
                            <img src={cuate} className="w-100" style={{ 'maxWidth': '74%' }} />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-5 pt-3">
                <Container>
                    <Row>
                        <div className="text-center">
                            <h2 className="test">FAQs</h2>
                        </div>
                    </Row>
                </Container>
            </section>

            <section className="mt-5">
                <Container>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What features are provided in the cloud call center or cloud contact center solution?</Accordion.Header>
                                    <Accordion.Body>
                                        CallerSpot offers a comprehensive cloud call center or contact center solution with various
                                        features. These include call routing, call recording, call analytics, interactive voice response
                                        (IVR), real-time monitoring, and agent performance tracking.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion className="mt-4">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How can I use call center software solutions for better call routing?</Accordion.Header>
                                    <Accordion.Body>
                                        To enhance call routing, CallerSpot&#39;s call center software allows you to set up intelligent
                                        routing rules based on various factors such as the caller&#39;s location, language preference,
                                        and previous interactions. This ensures that calls are directed to the most appropriate agent
                                        or department, resulting in improved customer service.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the use cases of contact center solutions?</Accordion.Header>
                                    <Accordion.Body>
                                        Contact center solutions provided by CallerSpot cater to various use cases, such as
                                        customer support, sales, appointment scheduling, and helpdesk services. The software can
                                        be customized to meet specific business requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What does the term IVR mean? How does it work?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR stands for Interactive Voice Response. It is an automated system that interacts with
                                        callers through voice prompts and touch-tone inputs. IVR works by presenting a menu of
                                        options to callers and allowing them to make selections using their telephone keypad. Based
                                        on the chosen option, the IVR system routes the call to the appropriate department or
                                        provides pre-recorded information.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. How can I set up a small contact center to manage 200 calls daily?</Accordion.Header>
                                    <Accordion.Body>
                                        To set up a small contact center handling 200 calls daily, you can start by obtaining
                                        CallerSpot&#39;s cloud call center software. It offers easy scalability and can be accessed
                                        remotely by your agents. Configure the routing rules, integrate with your existing telephony
                                        infrastructure, and train your agents to handle calls efficiently.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the features of cloud call center software?</Accordion.Header>
                                    <Accordion.Body>
                                        CallerSpot&#39;s cloud call center software offers various features, including automatic call
                                        distribution, call recording, real-time analytics, CRM integration, and agent performance
                                        monitoring. It provides a centralized platform for managing incoming and outgoing calls,
                                        ensuring efficient call handling and improved customer experience.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is an inbound call center?</Accordion.Header>
                                    <Accordion.Body>
                                        An inbound call center primarily handles incoming calls from customers or prospects seeking
                                        assistance, support, or information. Agents in an inbound call center are trained to handle a
                                        wide range of customer inquiries and provide solutions.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is an outbound call center?</Accordion.Header>
                                    <Accordion.Body>
                                        An outbound call center focuses on making outgoing calls to customers or prospects for
                                        sales, marketing, surveys, or collections purposes. Agents in an outbound call center are
                                        skilled in conducting sales pitches, lead generation, and gathering customer feedback.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are inbound and outbound call center services and features?</Accordion.Header>
                                    <Accordion.Body>
                                        Inbound call center services involve handling incoming customer calls, providing support,
                                        resolving issues, taking orders, and answering inquiries. Features may include call queuing,
                                        skill-based routing, call recording, and CRM integration. Outbound call center services
                                        involve making outgoing calls for sales, lead generation, market research, or survey
                                        purposes. Features may include auto-dialing, call scripting, campaign management, and
                                        performance tracking.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What are the differences between IVR and OBD?</Accordion.Header>
                                    <Accordion.Body>
                                        IVR (Interactive Voice Response) is an automated system that interacts with callers through
                                        voice prompts and touch-tone inputs, allowing them to navigate through a menu of options.
                                        OBD (Outbound Dialing) refers to the process of making outgoing calls to customers or
                                        prospects for various purposes, such as sales, marketing, or surveys. The main difference is
                                        that IVR is focused on inbound calls and navigation options, while OBD is about initiating
                                        outbound calls.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is cloud telephony?</Accordion.Header>
                                    <Accordion.Body>
                                        Cloud telephony is a communication technology that allows voice and data services to be
                                        supplied via the internet rather than traditional phone lines. It helps enterprises to set up and
                                        operate their phone systems in the cloud, providing flexibility, scalability, and cost savings.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} sm={12}>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Q. What is hosted IVR?</Accordion.Header>
                                    <Accordion.Body>
                                        Hosted IVR is an IVR system that is hosted and operated in the cloud by a service provider.
                                        Instead of investing in on-premises gear and software, organizations may benefit from the
                                        capability and scalability of IVR through the use of a hosted IVR solution.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );

};
export default missedcall;