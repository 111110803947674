import React, { Fragment } from "react";
import {
  Nav,
  Navbar,
  Container,
  NavDropdown,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";
import Image1 from "../../assets/callerspot1.svg";
import Image2 from "../../assets/download6.png";
import { Items } from "../navHeader/Items";
import MenuItems from "../navHeader/MenuItems";
import Dropdown from "rsuite/Dropdown";
import "rsuite/dist/rsuite.min.css";
import Aside from "./sidebar";
import { FaAlignLeft } from "react-icons/fa";

const Callto = ({ phone, children }) => {
  return (
    <a className="tel" href={`tel:${phone}`}>
      {children}
    </a>
  );
};
function Mailto({ email, subject, body, ...props }) {
  return (
    <a
      className="tel"
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
    >
      {props.children}
    </a>
  );
}

function Header() {

  return (
    <Fragment>
      <section className="nav-bg sticky hide desktop-view">
        <header>
          <div className="head1">
            <Container>
              <Row>
                <Col md={6} sm={12} lg={6}>
                  <div className="d-flex top-13">
                    <img src={Image2} className="flag-1" alt="3422" />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Callto phone="+919711665756">
                      +919711665756
                    </Callto>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <Mailto
                      email="info@callerspot.com"
                      subject="Hello"
                      body="Hello world!">
                      info@callerspot.com
                    </Mailto> */}
                  </div>
                </Col>
                <Col md={6} sm={12} lg={6}>
                  <div className="d-flex top-12 mt-1">
                    {/* <p>Sign up &nbsp;/&nbsp; Login</p> */}
                    <Mailto
                      email="info@callerspot.com"
                      subject="Hello"
                      body="Hello world!">
                      <p style={{ 'fontWeight': '900', 'color': '#fff' }}>info@callerspot.com</p>
                    </Mailto>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </header>

        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Navbar.Brand href="/">

              <img src={Image1} alt="3422" height={'40px'} />

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav classname="mx-auto">
                <ul className="menus">
                  {Items.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                      <MenuItems
                        items={menu}
                        key={index}
                        depthLevel={depthLevel}
                      />
                    );
                  })}


                  <li style={{ 'marginLeft': '10px' }}><a href="/trial-demo" className="btn btn-outline-secondary" style={{ 'borderRadius': '20px 20px 20px 20px' }}> Trial Demo </a></li>
                  <li className="tff" style={{ 'marginLeft': '10px' }}> <a href="/try-for-free" className="btn btn-primary" style={{ 'borderRadius': '20px 20px 20px 20px' }}>Try for Free </a></li>

                </ul>



              </Nav>
            </Navbar.Collapse>
            {/* <Button variant="secondary" className="get11">
              Get in Touch
            </Button>{" "} */}
          </Container>
        </Navbar>
      </section>

      {/* OFF CANVAS */}
      <section className="sticky canvass nav-bg ">
        {[false].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className=" canvass bg-color top-marg"
            style={{ height: "80px" }}
          >
            <Container>
              <Link to="/" className="lined">

                <img
                  variant="center"
                  className="image"
                  alt="techimage"
                  src={Image1}
                  style={{ width: "172px" }}
                />

              </Link>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="nav-icon"
              >
                <FaAlignLeft className="FaAlignLeft" />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
              >
                <Offcanvas.Header closeButton className="canvas-border">
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                    className="logos"
                  >
                    <img
                      variant="center"
                      className="image"
                      alt="techimage"
                      src={Image1}
                      style={{ width: "130px", height: "30px" }}
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body className="navtagscanvas">
                  <Aside />
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </section>
    </Fragment>
  );
}

export default Header;
